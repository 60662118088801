import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, Tooltip } from '@mui/material';

const FilterOperatorSelector = ({ filterOperatorList, value, onChange, filterType }) => (
  <FormControl variant="standard" sx={{ mx: 1, minWidth: 110, maxWidth: 110 }}>
    <InputLabel id="filter-operator-label" sx={{ backgroundColor: 'transparent' }}>
      {filterType === 'Group' ? '(Optional) Operator' : 'Operator'}
    </InputLabel>
    <Tooltip title={filterType === 'Group' ? '(Optional) Operator' : ''} 
      PopperProps={{
        style: { zIndex: 55 },
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -10],
            },
          },
        ],
      }}
    >
      <Select
        labelId="filter-operator-label"
        id="filter-operator-select"
        value={value||''}
        onChange={onChange}
        label="Operator"
        sx={{
          zIndex: 100,
          backgroundColor: 'transparent',
          '& .MuiSelect-select': {
            backgroundColor: 'transparent',
          },
          '&.Mui-selected': {
            backgroundColor: 'transparent !important',
          },
        }}
      >
        {filterOperatorList.map(operator => (
          <MenuItem key={operator} value={operator}>
            {operator}
          </MenuItem>
        ))}
      </Select>
    </Tooltip>
  </FormControl>
);

export default FilterOperatorSelector;
