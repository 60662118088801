import {useCompStore, useAdminSettingsStore, useLocalDataStore, usePersistedDataStore} from '../store.js';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import _, {isEqual} from 'lodash';
import Button from '@mui/material/Button';
import CurrencyInput from 'react-currency-input-field';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { updateMuniObj, getMunicipalityOptions, getVillageOptions, getCountyOptions } from '../../common/helper_functions.js';
import axiosInstance from '../../axiosConfig.js'
import NewDropdown from '../NewDropdown.js'
import Modal from '../../common/Modal.js'
import {useFetchCompsHook} from '../../common/fetchComps.js'
import CircularProgress from '@mui/material/CircularProgress';
import { saveComps } from '../../common/saveComps'
import { toast } from 'react-toastify';
import { useStreamProcessor } from '../../common/useStreamProcessor';

export default function MuniSelection({nyDataObj, yearDropdownOptions, upcomingCourtDates, globalCompRef}) {
    const getShowSettledCases = useAdminSettingsStore((state) => state.showSettledCases)
    const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj)
    const [displayOptions, setDisplayOptions] = useState(false);
    const setShowSettledCases = useAdminSettingsStore((state) => state.setShowSettledCases)
    const getDefaultNumCases = useAdminSettingsStore((state) => state.defaultNumCases)
    const setDefaultNumCases = useAdminSettingsStore((state) => state.setDefaultNumCases)
    const getManualReview = useAdminSettingsStore((state) => state.manualReview)
    const setManualReview = useAdminSettingsStore((state) => state.setManualReview)
    const getRar = usePersistedDataStore((state) => state.rar)
    const getEq = usePersistedDataStore((state) => state.eq)
    const [addedPID, setAddedPID] = useState('')
    const [error, setError] = useState(false)
    const [popup, setPopup] = useState(false)
    const setAdjustments = useCompStore((state)=> state.setAdjustments)
    const setNegotiationObj = usePersistedDataStore((state) => state.setNegotiationObj)
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const urlString = location.pathname+location.search
    const [loading, setLoading] = useState(false);
    const [scarOnly, setScarOnly] = useState(false);
    const MuniCode = queryParams.get('MuniCode') || 'All';
    // const comp = parseInt(queryParams.get('comp'));
    const [muniOptions, setMuniOptions] = useState([{value:'All', label:'All'}])
    const municipality = queryParams.get('municipality') || 'All';
    const county = queryParams.get('county') || 'All';
    const village = queryParams.get('village') || 'All';
    const courtDate = queryParams.get('CourtDate') || '';
    const taxYear = queryParams.get('TaxYear') || getNegotiationObj.TaxYear; // updating taxyear updates whole obj. Analyze refactors to see if performance issue in future.
    const repIDList = ['',1,2,3]
    const repID = queryParams.get('Rep') || '';
    const [villageOptions, setVillageOptions] = useState([{value:'All', label:'All'}])
    const [countyOptions, setCountyOptions] = useState([{ value: "All", label: "All", selectVal: 'All'},
      ...Object.keys(nyDataObj).map(key => ({
          label: key,
          value: nyDataObj[key].selectVal
      }))])
      const [fetchError, setFetchError] = useState(null);
      const setFetchIteration = usePersistedDataStore((state) => state.setFetchIteration);
      const { processStream, fetchIteration } = useStreamProcessor(setFetchIteration);

    useEffect(()=>{ // update the available counties when courtdate is updated.
      if (!courtDate || !county) {
        console.log('its setting default, could just return nothing here.')
        return
      }
      if(courtDate !== ''){
        // Set a loading widget with value for spinner for the county dropdown.

        console.log('loading the counties for the court date:')
        console.log(courtDate)
        loadCountyOptions(courtDate);
      }
    }, [courtDate]);

    const [courtMunis, setCourtMunis] = useState([]);

    useEffect(() => {
      // Update the available municipalities when the county is updated.
      if (courtDate !== '' && county !== '' && county !== 'All' && county !== 'select') {
        console.log('Loading the munis for the court date:');
        console.log(courtDate);
        loadMuniOptions();
      } else { // set the default muni options if no court date.
        getMunicipalityOptions({
          setMuniOptions,
          nyDataObj,
          county,
          courtMuni: [],
          taxYear,
          navigate,
          countyOptions,
        });
      }
      console.log(county);
    }, [county]);
  
    // really shit function and dumb, but works for now.
    async function loadMuniOptions() {
      console.log(courtDate);
      const courtMunis = await loadCourtMunis(courtDate);
      setCourtMunis(courtMunis);  // Store the fetched courtMunis in state
      getMunicipalityOptions({
        setMuniOptions,
        nyDataObj,
        county,
        courtMuni: courtMunis,
        taxYear,
        navigate,
        countyOptions,
      });
    }
  
    async function loadCountyOptions(courtDate) {
      const courtMunis = await loadCourtMunis(courtDate);
      setCourtMunis(courtMunis);  // Store the fetched courtMunis in state
      getCountyOptions({
        setMuniOptions,
        nyDataObj,
        county,
        courtMuni: courtMunis,
        taxYear,
        navigate,
        setCountyOptions,
      });
    }
  
    const loadCourtMunis = useCallback(async (courtDate) => {
      console.log('Running the load court munis function');
      const response = await axiosInstance.post(`/load_court_munis`, { CourtDate: courtDate });
      console.log(response.data);
      return response.data;
    }, [courtDate]);  // Add dependencies here if necessary
  
    // Memoized function to update municipality options
    const updateMuni = useCallback(({ updateField, updateValue, updateString = '', urlString }) => {
      updateMuniObj({
        updateField,
        updateValue,
        updateString,
        navigate,
        urlString,
        nyDataObj,
        county,
        taxYear,
        setCountyOptions,
        courtMuni: courtMunis,  // Pass courtMunis from state
      });
    }, [navigate, nyDataObj, county, taxYear, courtMunis]);

  // Combine the useeffect function of updatecourtmunioptions into a usememo that sets the munioptions.

  useEffect(()=>{ // would be better to memoize instead of useeffect, although computationally think makes no diff.
    console.log('running the get village useeffect (do this in the function for muniupdate')
    if(courtDate===''){
    getVillageOptions(setVillageOptions, nyDataObj, municipality, county)
    }
  },[municipality])



// refactored the initialization, removed adjustment load.
async function loadClientIds(muniObj) {
  let loading = true;
  let error = null;
      // Start loading clients and adjustments concurrently
      // Within the new client ids route, we also do filtering for if settled or not.
      const loadClientsPromise = await axiosInstance.post(`/load_client_ids`, muniObj);
      // const loadAdjustmentsPromise = axiosInstance.post(`/load_settlements`, muniObj);

      // Process clients once they arrive
      let clients = loadClientsPromise.data;
      // console.log(`${getTimeStamp()} Client loading done:`);
      // Update negotiation object
      const clientList = clients;
      loading = false;
      return clientList; // Return updated object or clients as needed
}

// this is a little retarded but it was the quickest way to get your existing code to work w/ brennans stream
const isFirstFetchRef = useRef(true);
const isSecondFetchRef = useRef(false); // Track second run

const handleFetchSettlements = async (updateNegotiationObj,addedPID=false) => {
  console.log('the negotiation object')
  console.log(updateNegotiationObj)
  // here, we are going to hit the fetchcomps hook to return the values of adj and settlements
  // let params = {
  //   addedPIDs: updateNegotiationObj.cases, updateNegotiationObj };
  
  //   // Update params if its a single comp run.
  // if(addedPID){
  //   params = {
  //     addedPID: addedPID, updateNegotiationObj, addedPIDs: updateNegotiationObj.cases
  //   }
  // }

  // console.log('params going into')
  // console.log(params)

        let updatedCases;
        if (addedPID) {
            let trimmedPID = addedPID.trim();
            updatedCases = [trimmedPID];
            updateNegotiationObj.cases = updatedCases;

            if (trimmedPID.length >= 3) {
                console.log('setting municode from trimmedpid')
                updateNegotiationObj.MuniCode = trimmedPID.substring(0, 3);
            } else {
                console.error("Error: PID is too short to extract MuniCode");
            }
        } 

        // updateNegotiationObj.scarOnly -- this is your field which says 'only give me scar settlements'
        const updatedNegotiationObj = _.cloneDeep(updateNegotiationObj);

        if (updatedNegotiationObj.cases.length === 0) {
          console.log('no cases, should add FE error handling here.')
          // this error handling no longer applies since you moved it out
            setFetchError(['No unsettled comps found in selected muni + year', '(or no tentative assessment exists)']);
            // return [];
        }

        // removed the fetchComps function entirely
        // this was unnecessary but helped you find a bug.
        // Start all fetches concurrently
        const loadAdjustmentsPromise = axiosInstance.post(`/load_adjustments`, updateNegotiationObj);
        // const loadSettlementsAndCompsResponse = await axiosInstance.post(`/load_muni_cases`, updateNegotiationObj);
        const loadSettlementsAndCompsPromise = axiosInstance.post(`/load_muni_cases`, updateNegotiationObj);

        const [loadAdjustmentsResponse, loadSettlementsAndCompsResponse] = await Promise.all([
            loadAdjustmentsPromise,
            loadSettlementsAndCompsPromise
        ]);

        let adjustments = null;
        const adjustmentResponse = loadAdjustmentsResponse.data;
        adjustments = adjustmentResponse.response;

        console.log(adjustments)
        // console.log('settlements response')
        // console.log(loadSettlementsAndCompsResponse)
        const settlementsResponse = loadSettlementsAndCompsResponse.data[0];
        updatedNegotiationObj.cases = settlementsResponse;

        if (updatedNegotiationObj.RAR === undefined || updatedNegotiationObj.RAR === null) {
            updatedNegotiationObj.RAR = getRar;
        }
        if (updatedNegotiationObj.EQ === undefined || updatedNegotiationObj.EQ === null) {
            updatedNegotiationObj.EQ = getEq;
        }

  // Check if the original object is longer than the response -- you're a fucking idiot and this was the cause of a "bug" that you took an hour to find.
  // if (originalCases.length > fetchedCases.length) { // this should no longer be relevant w/ the backend fix you did.
  //   const diff = originalCases.length - fetchedCases.length;
  //   // Add empty objects to the end of the fetched cases to match the original length
  //   for (let i = 0; i < diff; i++) {
  //     fetchedCases.push({});
  //   }
  // }
  // return fetchResponse;

  updatedNegotiationObj.MuniCode = updatedNegotiationObj.MuniCode;

  // here, if the backend returns a "clientRun" flag set negotiation obj clientRun equal to zero
  // updatedNegotiationObj.clientRun = fetchResponse.updatedNegotiationObj.clientRun;
  globalCompRef.current = {
    cases: updatedNegotiationObj.cases, // you can update this with a seperate function entirely.
    totalCases: updatedNegotiationObj.cases.length,
  };

  // ONLY PERFORM THIS if its a single run:
  if(addedPID && typeof updatedNegotiationObj.cases[0] === 'string'){
    console.log('this was not a client run, setting negotiation obj flag as such.')
    updateNegotiationObj.clientRun = 0; // this flag denotes this is being treated as a marketing run
  }
  setNegotiationObj(updatedNegotiationObj);
  setAdjustments(adjustments);
  return updatedNegotiationObj
};


// the streaming update to this function removed the helper function entirely. TBD if that is desired behavior or not.
const handleInitializeFetch = async (addedPID, manualOverride = false) => {
  let formattedCourtDate = courtDate 
  ? new Date(new Date(courtDate).getTime() - new Date(courtDate).getTimezoneOffset() * 60000)
      .toISOString()
      .split('T')[0] 
  : '';
  
  setLoading(true);
  
  globalCompRef.current = {
      properties: [],
      cases: [],
      totalCases: 0,
  };


  let updateNegotiationObj = {
      MuniCode,
      TaxYear: parseInt(taxYear),
      ...(formattedCourtDate ? { CourtDate: formattedCourtDate, CourtRun: 1 } : { CourtRun: 0 }),
      showSettledCases: getShowSettledCases,
      manualReview: getManualReview ? 1 : 0,
      manualReviewOverride: manualOverride ? 1 : 0,
      scarOnly: scarOnly ? 1 : 0,
      // Village flag is now getting set by the municode >3 digits on backend
      RepId: repID,
  };

  if (courtDate === '') {
      delete updateNegotiationObj.CourtDate;
  }

  // If you put in a single specific PID to run:
  if (addedPID) {
      // Directly invoke the fetch without awaiting the entire response\
      console.log('running a single pid ')

      // This does NOT allow you to run a village for a single comp run atm.
        updateNegotiationObj.cases = [addedPID];
        // set the municode equalt ot he first 3 digits of added PID
        updateNegotiationObj.MuniCode = addedPID.slice(0,3); // this line specifically cuases the village incompatiability.
        handleFetchSettlements(updateNegotiationObj,addedPID);
        // console.log('settlement results')
        // console.log(settlementResults)
        // You could also do this in the codeblock if there is NO results found when it searches
        // need to confirm that doesn't fuck the repID flow though.
        // HERE, if it is a non-client run, you need to load in comps
        // if(typeof updateingNegotiationObj.cases[0] === 'string'){
          // set your negotiation Object
        // }

        const fetchCompsResponse = await fetchKNNModel(updateNegotiationObj);

      if (fetchError) {
          console.log('Breaking execution, fetch errored.');
          return;
      }
  } else {
      // Our generic fetch for all cases
      const updatedCases = await loadClientIds(updateNegotiationObj);
      if (updatedCases.length === 0) {
          console.log('No client IDs found, aborting fetch.');
          return;
      }
      
      // set the value of your pids equalt o the clients. (passing null will fetch all)
      updateNegotiationObj.cases = updatedCases;

      if((!getShowSettledCases || scarOnly) && getManualReview){ 
        // if the user has 
        console.log('running in the loop as it should')
        const negotiationObjWithSettlements = await handleFetchSettlements(updateNegotiationObj);
        console.log(negotiationObjWithSettlements)
        // set the value of updateNegotiationObj.cases array by mapping over negotiationObjWithSettlements.cases and returning the PID field
        updateNegotiationObj.cases = negotiationObjWithSettlements.cases.map(caseItem => caseItem.PID);
      }
        // Reordered so you ALWAYS FETCH this first. then KNN after. also force await.
      const negotiationObjWithSettlements = await handleFetchSettlements(updateNegotiationObj);
      const fetchCompsResponse = await fetchKNNModel(updateNegotiationObj);
      // console.log(fetchCompsResponse)

      if(!getManualReview){ // this will only be true if you are not doing a manual review
        console.log('Entering the non-manual save function. and passing in:')
        console.log(updateNegotiationObj)
        const userObject = JSON.parse(localStorage.getItem('userInfo'))
        // iterate over the negotiation Object cases, and add in the value of the comps and PID (pid already exists in cases)
        const saveObject = {
          ManualReviewFlag: 0, 
          ManualOverride: manualOverride ? 1 : 0,
          // cases are passed in capital here. Stupid that this isn't consistent
          Cases: negotiationObjWithSettlements.cases, // pass in as an object so the function works to be modular to save all too.
          UserId: userObject.userId || null,
          VillageFlag: village!=='All' ? 1 : 0,
          TaxYear: taxYear,
          RepId: repID,
        }

        console.log(globalCompRef.current)
        // map over the saveObject, and update the value of each index of the cases with the value of globalCompRef.properties[1] slice of first 5
        saveObject.Cases = saveObject.Cases.map((item, index) => {
          // Create the compsObject from the first 5 items in globalCompRef's parcel_id slice
          const compsObject = globalCompRef.current.properties[1][index].parcel_id.slice(1, 6).reduce((acc, value, index) => {
            acc[index + 1] = value; // Map the index to the value
            return acc;
          }, {});
        
          const newCaseItem = {
            Subject: item.PID,
            Comps: compsObject,
            // repid is inconsistently named. dumb as fuck. Keys are lowercase "Id".
            ...(Array.isArray(globalCompRef.current.properties[0][index]?.RepID) && globalCompRef.current.properties[0][index].RepID[0] !== ''
            ? { RepId: globalCompRef.current.properties[0][index].RepID[0] }
            : { RepId: null })
          };
          return newCaseItem;
        });

        console.log(saveObject) // now, try to call the savecomps function and pass this in.
        const savingComps = await saveComps(saveObject)
        // This is redundant the actual save has a toast.
        // if(savingComps.message === "Comparables batch saved successfully"){
        //   toast.success(`Comparables updated`, {
        //     position: 'top-right',
        //     autoClose: 2000,
        //   });
        // }
        console.log(savingComps)      
      }
    }
    setLoading(false)

      // here, after all this, we should call save with the updated savecomp object (put comps into the negotiationobj cases)
};

// Modify the fetchKNNModel to handle streaming updates, invoke external stream function.
async function fetchKNNModel(updateNegotiationObj) {
  
  // handling the Base URl here bc axios doesn't support streaming in the same way as fetch.
  const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/KNNModel`, {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
      },
      body: JSON.stringify({
            MuniCode: updateNegotiationObj.MuniCode,
            TaxYear: updateNegotiationObj.TaxYear,
            CourtDate: updateNegotiationObj.CourtDate,
            CourtRun: updateNegotiationObj.CourtRun,
            uids: updateNegotiationObj.cases,
            // RepId: updateNegotiationObj.RepId,
            // Add in manual Review flags?
        }),
  });

  if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
  }

  console.log('the response object, body gets passed in. Its just a data with string [{...}')
  console.log(response)
  // Here, the process stream and fetch combined function.
  const streamProcessingResponse = await processStream({
    compStreamObjectRef: globalCompRef.current,
    stream: response.body, // pass this in to then get reader.
    isFirstFetch: isFirstFetchRef.current,
    isSecondFetch: isSecondFetchRef.current,
    negotiationObj: updateNegotiationObj,
    ManualReview: getManualReview, // if false, accumulate object, then at the end of this invoke savecomps
    
  });

  console.log('obj after the process') // this looks like it updated properly?
  console.log(globalCompRef.current,)
}

// can use fetch iteration to display wher eyou are in the stream fetching process
// HAVE THIS NUMBER UPDATE THE TOTAL NUM CASES IN THE DASHBOARD!
// console.log('fetch iteration')
// console.log(fetchIteration)


  const handleUserSelection = (selection) => {
    let manualOverride = false
    if(selection==='Delete them'){
      console.log('deleting manual comps')
      manualOverride = true
    }
    // call fetch
    handleInitializeFetch(addedPID, manualOverride)
    setPopup(false)
  }
  

  // This is a piece of shit component. Should be modularized, and mapped over. 
  // The handle select function casuses app level rerenders because it is coming down from the top level for no reason.
  // TBD if I revisit this in this refactor. Absolutely needs to be cleaned up, but works for now.
    return(
        <div className='justify-center m-auto  content-center bg-white px-8 pt-8 pb-6'>
          <div className=" m-auto justify-center content-center ">

            {/* This popup should be conditional on IF manual comps exist. for now */}
              {!getManualReview && popup &&
              // getPopup==='manualReview'? */}
               <Modal option1='Delete them' option2='Keep reviewed' header='How would you like to handle manually reviewed comps?'
              message='Would you like to override existing manually reviewed comps?.'
              onSelection={handleUserSelection}
              ></Modal>
              }
            {/* Main dropdowns element */}
            <div className='flex m-auto gap-4 justify-center items-end min-w-[190px]'>
              {/* County dropdown, always visible. */}
                <div className='countyDropdown'>
                    <NewDropdown
                          value={county}
                          label="County"
                          dropdownOptions={countyOptions.map(option => ({
                              value: option.value,
                              label: option.label
                          }))}
                          updateFunction={updateMuni}
                          updateFunctionArgs={{
                              updateField: 'county',
                              updateString: '', // assuming `option.label` is not needed
                              navigate,
                              urlString,
                              nyDataObj,
                              county,
                              TaxYear: taxYear,
                              setCountyOptions,
                          }}
                          disabled={countyOptions.length > 1 ? false : true}
                      />
                      {courtDate !== '' && county ==='select' ? <span className='absolute bottom-[-10] w-[150px] text-xs text-danger'>Select a County</span>
                      : null}
                </div>

              {/* Municipality dropdown, conditionally rendered. */}
              <div className='relative flex-col items-center'>
                    <NewDropdown
                        value={municipality}
                        label="Municipality"
                        dropdownOptions={muniOptions.map(option => ({
                            value: option.value,
                            label: option.selectVal,
                            county: option.county,   // Passing additional fields for updateFunction
                            TaxYear: option.TaxYear  // Passing additional fields for updateFunction
                        }))}
                        updateFunction={(...args) => {
                          // console.log('Arguments passed to updateMuni:', args);
                          updateMuni(...args);
                      }}
                        updateFunctionArgs={{
                            updateField: 'municipality',
                            updateString: '', // assuming `option.label` is not needed
                            urlString,
                            nyDataObj,
                            county,
                            navigate,
                            setCountyOptions,
                        }}
                        disabled={county === 'All' && courtDate=== '' || county==='select'}
                    />
                    {error ? <span className='absolute bottom-[-10] w-[150px] text-xs text-danger'>{error}</span>
                    : courtDate !== '' && municipality === 'select' && county !=='select' ? <span className='absolute bottom-[-10] w-[150px] text-xs text-danger'>Select a Municipality</span>
                      : null}
              </div>

              {/* Village */}
              <NewDropdown
                    value={village}
                    label="Village"
                    dropdownOptions={villageOptions.map(option => ({
                        value: option.value,
                        label: option.selectVal
                    }))}
                    updateFunction={updateMuni}
                    updateFunctionArgs={{
                        updateField: 'village',
                        updateString: '', // assuming `option.label` is not needed
                        urlString,
                        nyDataObj,
                        navigate,
                        county: null,  // You can pass actual county if available
                        TaxYear: null,  // You can pass actual TaxYear if available,
                        setCountyOptions,
                    }}
                    disabled={(municipality === 'All' && villageOptions[0] === 'All') || villageOptions.length > 1 ? false : true}
                />

              {/* Taxyear drop */}
              <NewDropdown
                  value={taxYear}
                    label="Tax Year"
                    dropdownOptions={yearDropdownOptions}
                    updateFunction={updateMuni}
                    updateFunctionArgs={{
                      updateField: 'TaxYear',
                      updateString: '',
                      navigate: useNavigate(),
                      urlString,
                      nyDataObj,
                      county: null,
                      TaxYear: taxYear,
                      setCountyOptions,
                  }}
                />
            </div>

      <div className='justify-center m-auto text-center content-center'>
        <span>
          OR: 
        </span>
        {/* Court date dropdown */}
        <div className='flex justify-center'>
          <div className='flex w-fit min- mt-2'>
                <NewDropdown
                    value={courtDate}
                    width={125}
                    label="Court Date"
                    dropdownOptions={
                        upcomingCourtDates.length < 1
                            ? []
                            : upcomingCourtDates.map(value => ({
                                  value: value,
                                  label: value,
                              }))
                    }
                    updateFunction={(...args) => {
                        updateMuni(...args);
                    }}
                    updateFunctionArgs={{
                        updateField: 'CourtDate',
                        updateString: '',
                        urlString,
                        nyDataObj,
                        navigate,
                        setCountyOptions: setCountyOptions,
                    }}
                />
          </div>
        </div>
      </div>
      
          <div className=' mt-6 border-t text-center min-w-[210px]'>
            <div className='my-1 text-sm flex items-center justify-center'>
              <div className="mr-0.5">Additional Options:</div>
              <button className="float items-center justify-center content-center" onClick={() => {
                setDisplayOptions(!displayOptions);
              }}>
                {displayOptions?
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-square-x"><rect width="18" height="18" x="3" y="3" rx="2" ry="2"/><path d="m15 9-6 6"/><path d="m9 9 6 6"/></svg>
                :
                // Up arrow:
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-square-arrow-down"><rect width="18" height="18" x="3" y="3" rx="2"/><path d="M12 8v8"/><path d="m8 12 4 4 4-4"/></svg>
                }
              </button>
            </div>

            {displayOptions?
            <div className='flex justify-center'>
            <div className='m-auto content-center justify-center'>


            <div className='my-3 flex justify-center m-auto content-center'>
            <FormControl className='flex w-fit min-w-[115px] justify-end' size='small' fullWidth>
            <InputLabel id="show-settled-label"
            sx={{zIndex: '10 !important', backgroundColor: 'white !important'}}
            >SCAR Only:</InputLabel>
                <Select
                    value={scarOnly} // set a default value for manualReview.
                    className='w-fit min-w-[115px]'
                >
                    {[true, false].map((value, index) => (
                        <MenuItem
                            key={index}
                            value={value.toString()} // Convert boolean to string to match the state type
                            onClick={()=>{setScarOnly(value); console.log(value);}}
                            style={{ minHeight: '30px' }} // Set your desired minimum height here
                        >
                            {value.toString()}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>

              {/* Settled cases */}
            <div className='my-1 flex justify-center m-auto content-center'>
            <FormControl className='flex w-fit min-w-[115px]' size='small' fullWidth>
              <InputLabel id="show-settled-label"
              sx={{zIndex: '10 !important', backgroundColor: 'white !important'}}
              >Show settled:</InputLabel>
              <Select
                  id="show-settled-select" // Add an id for the Select component
                  value={getShowSettledCases} // set a default value for manualReview.
                  onChange={(event) => setShowSettledCases(event.target.value==='true')}
                  className='w-fit min-w-[115px]'
                  labelId="show-settled-label" // Associate the label with this id
                  MenuProps={{
                      PaperProps: {
                          style: { minHeight: '30px' }, // Set your desired minimum height here
                      },
                  }}
              >
                  {[true, false].map((value, index) => (
                      <MenuItem
                          key={index}
                          value={value.toString()} // Convert boolean to string to match the state type
                      >
                          {value.toString()}
                      </MenuItem>
                  ))}
              </Select>
          </FormControl>

        </div>

            {/* DROPDOWN FOR MANUAL REVIEW */}
          <div className='my-3 flex justify-center m-auto content-center'>
            <FormControl className='flex w-fit min-w-[115px] justify-end' size='small' fullWidth>
            <InputLabel id="show-settled-label"
            sx={{zIndex: '10 !important', backgroundColor: 'white !important'}}
            >Manual Review:</InputLabel>
                <Select
                    value={getManualReview} // set a default value for manualReview.
                    className='w-fit min-w-[115px]'
                >
                    {[true, false].map((value, index) => (
                        <MenuItem
                            key={index}
                            value={value.toString()} // Convert boolean to string to match the state type
                            onClick={()=>{setManualReview(value); console.log(value); if(!value){setPopup(true)}}}
                            style={{ minHeight: '30px' }} // Set your desired minimum height here
                        >
                            {value.toString()}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>

            {/* Rep ID */}
            <div className='my-1 flex justify-center m-auto content-center'>
            <NewDropdown
                value={repID}
                label="RepID"
                dropdownOptions={repIDList.map(id => ({
                    value: id,
                    label: id === null ? 'None' : id,
                }))}
                updateFunction={updateMuni}
                updateFunctionArgs={{
                    updateField: 'Rep',
                    updateString: '',
                    urlString,
                    nyDataObj,
                    navigate,
                }}
            />
        </div>


            <div className={`flex max-h-[30px] mt-3 justify-end`}>
              <span className={`font-bold pr-2 `}>
                Run "x" cases:
              </span>
                <fieldset className=" dark:text-gray-100 ">
                  <div className="flex">
                    <CurrencyInput type="text"
                      name="settlement"
                      // prefix=""
                      id="settlement"
                      placeholder="ex: 50"
                      className={`flex flex-1 text-center max-w-[80px] text-black sm:text-sm rounded-md focus:ri p-1 border`}
                      onChange={(e) => setDefaultNumCases(e.target.value)}
                      autoComplete="off"
                      // Disable your input for settlement when it is settled. (should also gray it out more)
                      value={
                        getDefaultNumCases ?
                        getDefaultNumCases
                        :
                        ''
                      }
                    />
                  </div>
                </fieldset>
            </div>

            <div className='mt-1'>
              <span className='text-xs font-bold'>
              Run one Comp:
              </span>
            <fieldset className=" dark:text-gray-100">
                <div className="flex">
                  <input type="text"
                    id="PID"
                    placeholder="Parcel ID"
                    className="flex flex-1 p-1 text-xs text-black text-center border w-[240px] mx-1 rounded-md focus:ri dark:border-gray-700 dark:text-gray-100 dark:bg-gray-800"
                    onChange={(e) => setAddedPID(e.target.value)}
                    value={addedPID || ''}
                  />
                    </div>
              </fieldset>
              </div>
          </div>
          </div>
            :null}
        </div>
      </div>

      {/* Button to fetch comps */}
      <div className='flex items-center mt-4'>
        <Button
          variant="contained"
          className='m-auto flex items-center relative' // Use relative positioning for the button container
          onClick={() => {
            if (courtDate !== '' && municipality === 'All') {
              setError('Select a Municipality');
            }
            handleInitializeFetch(addedPID);
          }}
          disabled={loading} // Optionally disable button when loading
        >
          Generate Comparables
          {loading && (
            <CircularProgress
              size={20}
              sx={{
                color: 'white',
                position: 'relative', // Use relative positioning for the spinner
                marginLeft: '16px',   // Add some space between the text and the spinner
              }}
            />
          )}
        </Button>
      </div>

      {/* display error text under */}
      <div className='text-sm text-red-500 text-center h-[20px]'>
        {fetchError && 
        <div className='flex flex-col'>
        <span>
        {fetchError[0]}
        </span>
        <span>
        {fetchError[1]}
        </span>
        </div>

        }
      </div>

    </div>
    )
}