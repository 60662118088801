import React, { useState, useCallback, useMemo } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FilterTypeSelector from './FilterTypeSelector';
import FilterColumnSelector from './FilterColumnSelector';
import FilterOperatorSelector from './FilterOperatorSelector';
import FilterValueInput from './FilterValueInput';
import { DateRangePicker, SingleDatePicker } from './DateRangePicker';
import IconButton from '@mui/material/IconButton';
import dayjs from 'dayjs';

const FilterComponent = ({ onFilterChange, axisOptions }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filters, setFilters] = useState([{ type: 'Filter', column: '', operator: '', value: '', dataType: '' }]);
  
  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const handleApplyFilters =() => {
    console.log('filters from filter component')
    console.log(filters)
    onFilterChange(filters);
    setIsOpen(false);
    // THIS is where it should call the rernder of graph.
  }

  return (

    <div className="flex items-center justify-center">
      <div className="rounded p-2 flex items-center justify-center">
        <div className="relative inline-block">
        <div className='flex items-center rounded-md'>
        <button
          className={`rounded-md ${
            isOpen
              ? 'border-blue-500 border-2 border'
              : 'border-gray-300 hover:border-black border'
          }`}
          onClick={handleButtonClick}
          style={{ minWidth: '8rem', minHeight: '2.5rem' }} // Add this line to set a fixed width and height for the button
        >
          <FilterListIcon />
          <span className="ml-0.5">Filter/Group</span>
        </button>
        </div>

          {isOpen && (
            <FilterPanel
              onClose={() => setIsOpen(false)}
              filters={filters}
              filterOptions={axisOptions}
              setFilters={setFilters}
              onFilterChange={onFilterChange}
              onApplyFilters={handleApplyFilters}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const FilterPanel = ({ filters, onFilterChange, setFilters, filterOptions, onApplyFilters }) => {
  const handleFilterChange = (index, updatedFilter) => {
    console.log('updating filtetr state')
    const newFilters = filters.map((filter, i) => (i === index ? updatedFilter : filter));
    setFilters(newFilters);
  }

  const handleAddFilter = () => {
    setFilters([...filters, { type: '', column: '', operator: '', value: '', dataType: '', name: '' }]);
  };

  // const handleUniversalGroup = (e) => { // can't change object structure like this.
  //   setFilters({universalGroupName:e.target.value, filters:[...filters]});
  // };

  const handleRemoveFilter = (index) => {
    if(filters.length === 1) {
      setFilters([{ type: '', column: '', operator: '', value: '', dataType: '', name: '' }]);
      return;
    }else{
    setFilters(filters.filter((_, i) => i !== index));
    }
  };

  return (
    <div className="absolute top-full z-[99] mt-2 bg-white rounded px-1 py-1 right-0 mui-shadow-replica">
      {filters.map((filter, index) => (
        <Filter
          key={index}
          filterData={filter}
          filterOptions={filterOptions}
          onFilterChange={(updatedFilter) => handleFilterChange(index, updatedFilter)}
          onRemove={() => handleRemoveFilter(index)}
        />
      ))}
      <div className="flex justify-between m-auto mt-4">
        <Button
          variant="standard"
          className="relative p-2 bg-transparent group text-blue-400 hover:bg-blue-100 hover:opacity-80 inset-0 bg-blue-100 transition-opacity duration-300"
          onClick={handleAddFilter}
          startIcon={<AddIcon />}
        >
          Add constraint
        </Button>

        {/* temporary, checkbox, and then universal group name. */}
        {/* <input className='relative p-2 bg-transparent group text-blue-400 hover:bg-blue-100 hover:opacity-80 inset-0 bg-blue-100 transition-opacity duration-300'
        type='text'
        placeholder='Universal Group Name'
        onChange={(e) => handleUniversalGroup(e)}
        >
        </input> */}

        <Button
          className="relative p-2 bg-transparent group text-blue-400 hover:bg-blue-100 hover:opacity-80 inset-0 bg-blue-100 transition-opacity duration-300"
          onClick={onApplyFilters}
          startIcon={<CheckCircleIcon color="primary" style={{ color: '#60A5FA' }} />}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

const Filter = ({
  filterType,
  filterOptions,
  onFilterChange,
  filterData,
  onRemove,
}) => {
  console.log(filterData)

  // filter data is really top levle filter vs. group info.

  const stringFilterOperator = ['contains', 'startsWith', 'endsWith'];
  const numericFilterOperator = ['>', '<', '=', '!=', '>=', '<='];
  const boolFilterOperator = ['='];

  // Get the type of the selected filter value
  const selectedFilterOption = useMemo(() => { // update this to be the whole thing and use for all tooltips.
    return filterOptions.find(option => option.value === filterData.column) || {};
  }, [filterData.column, filterOptions]);

  // Memoize filter operators list based on the selected filter type
  const filterOperatorList = useMemo(() => {
    switch (selectedFilterOption.type) {
      case 'numeric':
        return numericFilterOperator;
      case 'bool':
        return boolFilterOperator;
      case 'date':
          return numericFilterOperator;
      case 'string':
        return stringFilterOperator;
      default:
        return [''];
    }
  }, [selectedFilterOption.type]);

  
  // Calculate the default operator based on the current filterOperatorList
  const defaultOperator = useMemo(() => filterOperatorList[0] || '', [filterOperatorList]);

  const handleTypeChange = (event) => {
    onFilterChange({ 
      ...filterData, 
      type: event.target.value,
    });
  };

  const handleFilterColumnChange = (event) => {
    if (filterData.type === 'Group') {
      onFilterChange({
        ...filterData,
        column: event.target.value,
      });
      return;
    }

    const selectedFilterValue = event.target.value;
    console.log(selectedFilterValue)
    const selectedOption = filterOptions.find(option => option.value === selectedFilterValue);
    const newOperatorList = selectedOption ? 
      (selectedOption.type === 'numeric' || selectedOption.type ==='date' ? numericFilterOperator : 
      (selectedOption.type === 'bool' ? boolFilterOperator : stringFilterOperator)) : [];

    onFilterChange({ 
      ...filterData, 
      operator: newOperatorList[0] || defaultOperator, // Use the default operator if list is empty
      column: selectedFilterValue,
      dataType: selectedOption ? selectedOption.type : '' 
    });
  };

  const handleFilterOperatorChange = (event) => {
    onFilterChange({ ...filterData, operator: event.target.value });
  };

  const handleFilterValueChange = (event) => {
    onFilterChange({ ...filterData, value: event.target.value });
  };

  const handleGroupNameChange = (event) => {
    onFilterChange({ ...filterData, name: event.target.value });
  };

  const handleFilterDateValueChange = (date, label=null) => {
    const newDate = date ? dayjs(date) : null;
    console.log(date)
    
    if(label === null){
      onFilterChange({
        ...filterData, 
        dataType: 'date',
        value: newDate
      });
    } else{
    onFilterChange({
      ...filterData, 
      value: {
        ...filterData.value,
        dataType: 'date',
        [label]: newDate,
      }
    });
  }

  };

  return (
    <div className='flex items-center'>

      {/* Delete current filter */}
       <button className="m-auto p-1 mt-4 items-center rounded-full hover:bg-gray-100" onClick={onRemove}>
         <ClearIcon />
       </button>

       <FilterValueInput
          value={filterData.name}
          onChange={handleGroupNameChange}
          filterType={filterData.type}
          label='Group Name'
          disabled={filterData.type === 'Filter'}
          type='Name'
        />

      <FilterTypeSelector
        typeOptions={['Filter', 'Group']}
        value={filterData.type}
        onChange={handleTypeChange}
      />


      <FilterColumnSelector
        filterOptions={filterOptions}
        value={filterData.column}
        onChange={handleFilterColumnChange}
        tooltipText="Select column to filter"
      />

      {/* What condition are we applying */}
      {/* {selectedFilterOption.type !== 'dateRange'&& ( // this is being violated it seems? */}
      <FilterOperatorSelector
        filterOperatorList={filterOperatorList}
        value={filterData.operator || (filterData.type === 'Filter' ? defaultOperator : undefined)}
        onChange={handleFilterOperatorChange}
        filterType={filterData.type}
      />
      {/* )} */}

      {selectedFilterOption.type === 'dateRange' ? (
        // delete the date range
        <DateRangePicker
          fromValue={filterData.value.from}
          toValue={filterData.value.to}
          onFromChange={(value) => handleFilterDateValueChange(value, 'from')}
          onToChange={(value) => handleFilterDateValueChange(value, 'to')}
        />
      ) : selectedFilterOption.type === 'date' ? (
        <SingleDatePicker
          value={filterData.value}
          onDateChange={(value) => handleFilterDateValueChange(value)}
        />
      )
      // default value is the normal value input.
       : (
        // if a bool make this a select for T/F
        <FilterValueInput
          value={filterData.value}
          onChange={handleFilterValueChange}
          filterType={filterData.type}
          dataType={filterData.dataType}
        />
      )}
    </div>
  );
};

export default FilterComponent;
