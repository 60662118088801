import React, { } from 'react';
import { isEqual } from 'lodash'; // Assuming you are using lodash for deep comparison
import GraphOverlay from './GraphOverlay.js';

const GraphContainer = ({
    nyDataObj,
    yearDropdownOptions,
}) => {
    // updated this entire component. use this only to render multiple plots now.
    
    // async function loadOptions(muniObj){ // consolidate the options load into your other call.
    //     setMicroLoad(true)
    //     try {
    //         const visualizationObject = {
    //             ...muniObj,
    //             PlotType: chartType,
    //           };
    //         const visualizationFeatures = await axiosInstance.post(`/load_viz_features`, visualizationObject);
    //         const axisOptions = visualizationFeatures.data;
    //         console.log(axisOptions)
    //     } catch (error) {
    //         // Handle the error here
    //         setPlot('')
    //         setGraphError('Uncaught Data error. Try a different Year/Area')
    //         console.error('An error occurred while loading visualization features:', error);
    //         toast.error(`${error} `, {
    //             position: 'top-left',
    //             className: 'mt-10',
    //             autoClose: 3000,
    //         });
    //         // Optionally, you can update the state to reflect the error condition
    //     }
    // }
    return (
        <div className='content relative flex flex-col h-full bg-gray-200'>
            
            {/* This acts as the headers, and currently graph overlay. */}
            <div className='content relative h-full'>
                <GraphOverlay nyDataObj={nyDataObj} yearDropdownOptions={yearDropdownOptions} >
                    {/* here we need to pass in default params IF they've been set from loadgraph. */}
                </GraphOverlay>
            </div>

        </div>
    );
}

const deepCompare = (prevProps, nextProps) => isEqual(prevProps, nextProps);

export default React.memo(GraphContainer, deepCompare);
