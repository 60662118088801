import React, { useEffect, useState } from 'react';
import Message from './Message';

const ChatWindow = ({ conversation, onTypingComplete }) => {
    console.log(conversation)
    return (
        <div className=''>
            {conversation.map((message, index) => (
                <Message
                    key={index}
                    msgIndex={index}
                    sender={message.sender}
                    text={message.text}
                    hasTyped={message.hasTyped}  // Pass hasTyped for each message
                    onTypingComplete={onTypingComplete}
                />
            ))}
        </div>
    );
};

export default ChatWindow;
