import React, { useState, useEffect } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InputBase from '@mui/material/InputBase';
import CircularProgress from '@mui/material/CircularProgress';
import { useStreamProcessor } from '../../common/useStreamProcessor';
import { usePersistedDataStore, useLocalDataStore } from '../store.js';
import {useNavigate, useLocation} from 'react-router-dom';

const CompNavigation = ({ comps, handleUpdateStateCallback, globalCompRef }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [inputValue, setInputValue] = useState('1');
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const [loading, setLoading] = useState(true);
    const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj);
    const getCompSheet = usePersistedDataStore((state) => state.compSheet);
    const getOptimizedComps = usePersistedDataStore((state) => state.optimizedComps);
    const setCompSheet = usePersistedDataStore((state) => state.setCompSheet);
    const setOptimizedComps = usePersistedDataStore((state) => state.setOptimizedComps);
    const setNegotiationObj = usePersistedDataStore((state) => state.setNegotiationObj);
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const getReviewPage = useLocalDataStore((state)=> state.reviewPage)
    const setReviewPage = useLocalDataStore((state)=>state.setReviewPage)

    const comp = parseInt(searchParams.get('comp')) - 1 || 0;
    
    // Idk that the setter and stream processor invocation is neceessary in this function, but is lightweight so nbd.
    const setFetchIteration = usePersistedDataStore((state) => state.setFetchIteration);
    const fetchIteration = usePersistedDataStore((state) => state.fetchIteration);
    const { processStream } = useStreamProcessor(setFetchIteration);

    useEffect(() => { // probably should be non-useeffect.
        if(fetchIteration===getNegotiationObj.cases.length || fetchIteration > getNegotiationObj.cases.length) {
            setLoading(false);
        }
      }, [fetchIteration]);

    //   HERE - have your compnav change function comparing the ref length to the actual object, udate state if its not yet done.
    // console.log(compRef.current)
    // here, if the state of your negotiation Object .length is < compRef state, you need to update
    // Update your optimize comps, your 

    // update comp with the actual comp thing.

    // For demonstration purposes
    // here, pass in the comps array length from parent.

    
    // Abstract hte "handlenext" behavior into a funciton which you can apply to the previous, or inputchange functions too.


    // Handle input change from InputBase (debounced)
    const handleInputChange = (event) => {
        const value = event.target.value;
        setInputValue(value); // Set the input as typed (including empty values)
        handleUpdateStateCallback()

        // Clear any existing debounce timeout
        if (debounceTimeout) clearTimeout(debounceTimeout);

        // Set a new debounce timeout
        const timeout = setTimeout(() => {
            const numValue = parseInt(value, 10);
            if (isNaN(numValue)) {
                // If input is invalid or empty, wait 1 second before resetting
                const blankTimeout = setTimeout(() => {
                    setInputValue((comp).toString()); // Reset to current index (1-based)
                }, 1000);
                setDebounceTimeout(blankTimeout); // Store this timeout in state to manage it
                return;
            }
            if (numValue < 1) {
                // Updat ethis so that after the elses it navigates, and sets search params her einstead of state
                searchParams.set('comp', 1);
            } else if (numValue > getCompSheet.length) {
                // setCurrentIndex(comps.length - 1); // If too high, set to the last index
                searchParams.set('comp', getCompSheet.length);
            } else {
                // setCurrentIndex(numValue - 1); // Set the index based on valid user input (1-based to 0-based)
                searchParams.set('comp', numValue);
            }
            navigate(`${location.pathname}?${searchParams.toString()}`);
        }, 300);

        setDebounceTimeout(timeout);
    };

    // Keep input value in sync with currentIndex
    useEffect(() => {
        setInputValue((comp+1).toString()); // Update input to reflect currentIndex
    }, [comp]);

    const handlePrev = () => {
        const url = new URL(location.pathname+location.search, window.location.origin);
        const searchParams = new URLSearchParams(url.search);
  
        if(getReviewPage){
          setReviewPage(false)
        }else if(comp===0){ 
          return // don't do anything if at the beginning of list.
        }else{
          searchParams.set('comp', comp);
        }
          
          // INVOKE THE FUNCTION TO COMPARE REF AND SET STATE
          handleUpdateStateCallback()
          const newUrlString = `${url.pathname}?${searchParams.toString()}`;
          navigate(newUrlString);
        // setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    };

    const handleNext = () => {
        // setCurrentIndex((prevIndex) => (prevIndex < comps.length - 1 ? prevIndex + 1 : prevIndex));
        const url = new URL(location.pathname+location.search, window.location.origin);
        const searchParams = new URLSearchParams(url.search);
        const comp = parseInt(searchParams.get('comp'));
         if(comp<getCompSheet.length){ // run normal function if you're not on last comp.
          // Here, as you move, update your higher level state object with sequential fetch runs.
          handleUpdateStateCallback()
        //   if (globalCompRef.current?.properties[0].length > getCompSheet.length) {
        //     // console.log('updating to match our ref object')
        //     const updateCompSheet = [...getCompSheet];
        //     const updateOptimizedComps = [...getOptimizedComps];
        
        //     // Append new values from properties if they exceed the current length
        //     const newCompSheet = globalCompRef.current.properties[0].slice(updateCompSheet.length);
        //     // console.log('comps to add in from new sheet: ', newCompSheet);
            
        //     // Use spread operator to append new values without mutating the array
        //     const mergedCompSheet = [...updateCompSheet, ...newCompSheet];
        //     // console.log('the updated compsheet post merge: ', mergedCompSheet);
            
        //     const newOptimizedComps = globalCompRef.current.properties[1].slice(updateOptimizedComps.length);
        //     const mergedOptimizedComps = [...updateOptimizedComps, ...newOptimizedComps];
        //     // console.log('the updated optimized compsheet post merge: ', mergedOptimizedComps);
        //     //   console.log(testNegotiationObj)
  
        //     // i want to update the negotiation Object so it has address, town and zip in the cases.
        //     // it is absurdly stupid for this to happen on the frontned. But quickest way.
        //     setCompSheet(mergedCompSheet);
        //     setOptimizedComps(mergedOptimizedComps);
        //     // setNegotiationObj(testNegotiationObj);
        //   }
          // change the index of this for non-array operations.
          searchParams.set('comp', comp+1);
          const newUrlString = `${url.pathname}?${searchParams.toString()}`;
          navigate(newUrlString);
        }
        // may need to do the same +1 to the comp here since this t
        else if(comp===getNegotiationObj.cases.length){ // on last comp, write to DB and show summary page.
          console.log(comp)
          const updatedNegotiationObj = {
            ...getNegotiationObj,
            cases: getNegotiationObj.cases.map((caseObj, index) => {
              return {
                ...caseObj,
                Address: getCompSheet[index].Address[0],
                Town: getCompSheet[index].Town[0],
                ZipCode: getCompSheet[index].ZipCode[0],
              };
            })
          };
          setNegotiationObj(updatedNegotiationObj);
          // Don't do anything else if you are at the end of the sheet.
          // review page doesn't work rn? it goes + at the right time ( I think?)
          setReviewPage(true)
        }
    };


    // Can put hover state for the background being gray, but I don't love how the icon looks rounded w/ too large of a bg atm.
    return (
        <div className="flex items-center justify-end">
            <button
                onClick={handlePrev}
                disabled={comp === 0}
                className="flex items-center py-1 text-black hover:text-gray-700 transition-colors duration-300 disabled:text-gray-400 disabled:cursor-not-allowed disabled:bg-transparent"
            >
                <ChevronLeftIcon />
            </button>

            {/* MUI InputBase Field for entering the index with minimal styling */}
            <InputBase
                value={inputValue}
                onChange={handleInputChange}
                type="text"
                inputProps={{
                    style: {
                        textAlign: 'center',
                        padding: '0',
                        width: `${inputValue.length+1}ch`, // Dynamic width based on character length
                        minWidth: '25px', // Set a minimum width
                        maxWidth: '40px', // Optional max width to prevent it from being too large
                    },
                }}
                className=" text-lg border-none focus:outline-none"
                style={{ fontSize: '1rem', textAlign: 'center', padding: '0', width: 'auto' }} // Remove border and custom width
            />

            <span className="text-lg min-w-[30px]">/ {fetchIteration}</span>

            {loading?
            <div className="flex items-center justify-center ml-1">
                <CircularProgress size={18} sx={{ color: 'gray' }}/>
            </div>
            :null}

            <button
                onClick={handleNext}
                // This disabled state actually never gets triggered (which is desired behavior)
                disabled={currentIndex === getCompSheet.length}
                className="flex items-center py-1 pl-1 text-black transition-colors duration-300 disabled:text-gray-400 disabled:cursor-not-allowed disabled:bg-transparent"
            >
                <ChevronRightIcon />
            </button>
        </div>
    );
};

export default CompNavigation;
