import React from 'react';
import { FormControl, Select, TextField, Tooltip } from '@mui/material';

const FilterValueInput = ({ value, onChange, filterType, label='Value', disabled, type, dataType }) => {
  // console.log(filterType)
  // Log the valuetype of your filter in here to determine how it shoul dbe conditionally formatted
  // formatted as number, etc.
  
  return (

  <FormControl variant="standard" sx={{ mx: 1, minWidth: 110, maxWidth: 110 }} >
    
      <Tooltip title={type === 'Name'?null:filterType === 'Group' ? '(Optional) Value' : null} disableInteractive
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -10],
            },
          },
        ],
      }}
    >
      {/* {dataType === 'bool' ? (
      <Select
        value={value}
        onChange={onChange}
        disabled={disabled}
        sx={{
          '& .MuiInputLabel-root': { backgroundColor: 'transparent' },
          '& .MuiInputBase-root': { cursor: 'pointer' },
          '&.Mui-selected': {
            backgroundColor: 'transparent !important',
          },
        }}  
      >
        <option value={1}>True</option>
        <option value={0}>False</option>
      </Select>
    ) : ( */}
      <TextField
        variant="standard"
        placeholder={type === 'Name' ? label : filterType === 'Group' ? '(Optional) Value' : 'Value'}
        value={value}
        onChange={onChange}
        label={type === 'Name' ? label : filterType === 'Group' ? '(Optional) Value' : 'Value'}
        disabled={disabled}
        sx={{
          '& .MuiInputLabel-root': { backgroundColor: 'transparent' },
          '& .MuiInputBase-root': { cursor: 'text' },
          '&.Mui-selected': {
            backgroundColor: 'transparent !important',
          },
        }}
      />
    {/* )} */}
      </Tooltip>

  </FormControl>
);
}
export default FilterValueInput;
