import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import {usePersistedDataStore} from '../store.js'
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import SMSVerification from './SMSVerification'
import CircularProgress from '@mui/material/CircularProgress';
import { debounce } from 'lodash';
// import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FormHelperText from '@mui/material/FormHelperText';
import Container from '@mui/material/Container';


// need to format the desktop view, basically just combine view #1 and 3 into a single one. 
// Then have the SMS verification be the last step
function CaptureForm({ feePercent, service = true }) {
  const getTermsAcceptedFlag = usePersistedDataStore((state) => state.termsAccepted);
  const setTermsAcceptedFlag = usePersistedDataStore((state) => state.setTermsAccepted);
  const queryParams = new URLSearchParams(window.location.search);
  const formStep = useMemo(() => parseInt(queryParams.get('formStep'))||1, [window.location.search]);
  const [activeStep, setActiveStep] = useState(formStep);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);

  // Update ischeckboxchecked to be a persised data state of (termsAccepted)
  // then you can directly set it from the terms and conditions route, and navigate the user back to their form (but be sure to keep the current url params)
  const [photo, setPhoto] = useState(null); // no longer doing the photo upload here
  const contractLink = '/contract';

  // Sms states
  const [error, setError] = useState(false);

  // here in verified - set the default to be true IF the smsVerified parameter exists.
  const [verified, setVerified] = useState(queryParams.get('smsVerified')==='true'||false);
  const [loading, setLoading] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)
  
    // Default state of form values
    const defaultFormValues = {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      relationship: '',
      notes: '',
    };

  // default value for your form
  const memoizedFormValues = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    console.log('recalculating form values')
    console.log(window.location.search)
    const newFormValues = {};
    
    // Loop through the form values and set them based on URL parameters
    Object.keys(defaultFormValues).forEach((key) => {
      newFormValues[key] = params.get(key) || '';  // If the param is not found, use the default empty string
    });

    return newFormValues;
  }, [window.location.search]);  // Recompute when the query parameters change

  const [formValues, setFormValues] = useState(memoizedFormValues);
  
  const [formErrors, setFormErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    relationship: false,
    notes: false,
  });

  const steps = 4;

  const validateForm = useCallback(() => {
    console.log('form validation running');
    let hasErrors = false;
    const newErrors = {};
  
    // Validate all required fields
    const firstValidation = ['firstName', 'lastName', 'email', 'phone'];
    const secondValidation = ['relationship'];
  
    const validationArray = activeStep === 1 ? firstValidation : activeStep === 3 ? secondValidation : [];
  
    // Perform validation checks
    validationArray.map((field) => {
      if (formValues[field].trim() === '') {
        newErrors[field] = true;
        hasErrors = true;
      } else {
        newErrors[field] = false;
      }
    });
  
    // Check the checkbox status if on the relevant step (activeStep === 3)
    if (activeStep === 3) {
      if (!getTermsAcceptedFlag) {
        setError(true)
        // newErrors['checkbox'] = true; // Custom error field for the checkbox
        hasErrors = true;
      } 
    }
  
    // Break the validation if there are errors
    if (hasErrors) {
    setFormErrors(newErrors);
      return false;
    }
  
    if (activeStep === 1 && !hasErrors) {
      // If you're on the first form state, and have no errors, go next.
      return true;
    }
  
    if (activeStep === 2 && !verified) {
      console.log('not verified');
      setError(true);
      return false;
    }
    return true;
  }, [formValues, activeStep, verified, getTermsAcceptedFlag]);  // Dependencies list to re-run the callback on change of any relevant state
   

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setTermsAcceptedFlag(false);
  };

  // Move to next part of form
  const handleNextStep = useCallback(async() => {
    window.scrollTo(0, 0);
    const formResponse = await validateForm();
    console.log(formResponse);
  
    if (!formResponse) {
      return;
    }
  
    setActiveStep((prevActiveStep) => prevActiveStep + 1); // Directly increment the step without setting state twice
  }, [validateForm]); // Add `validateForm` as a dependency so the callback updates if the validation function changes

  // Step back int he form
  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep > 1) {  // Only decrement if the step is greater than 1
        const newStep = prevActiveStep - 1;
        return newStep;
      }
      return prevActiveStep;  // Don't change step if it's 1
    });
  };

  const handleAgree = () => {
    setError(false)
    setIsDialogOpen(false);
    setTermsAcceptedFlag(true);
  };

  // Synchronize URL params with the state
  const updateUrlParams = useCallback(debounce((updatedValues) => {
    console.log('its updating params')
    // Get the current URL parameters
    const currentParams = new URLSearchParams(window.location.search);
    
    // Update or add the provided parameters
    Object.entries(updatedValues).forEach(([key, value]) => {
      currentParams.set(key, value);
    });
  
    // Construct the new URL with the updated parameters
    const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
    
    window.history.pushState(null, '', newUrl);
  }, 500), []);
  

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Update formValues
    setFormValues((prev) => {
      const updatedValues = { ...prev, [name]: value };
      
      // Update error state
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value.trim() === '' ? true : false,  // Set error if empty
      }));
      updateUrlParams(updatedValues);  // Update URL parameters
      return updatedValues;
    });
  };

  const handleCheckboxChange = (event) => {
    if(getTermsAcceptedFlag){
      setTermsAcceptedFlag(false)
      return
    }
    setIsDialogOpen(true)
  };

  // Need to update this with real backend verificaiton logic
  const handleSMSVerification = (otp) => {
    // console.log('sms verif running')
    setLoading(true);
    // Simulate a delay for validation (e.g., backend call)
    setTimeout(() => {
      if (otp === '111111') {
        const currentParams = new URLSearchParams(window.location.search);
        // Update or add the provided parameters
        currentParams.set('smsVerified', 'true');
        const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
        window.history.pushState(null, '', newUrl);
        setVerified(true);
        setError(false);
        console.log(`Verification successful: ${otp}`);
        // Unfocus the input after verification
        // Trigger button loading and callback after 0.5 seconds
        setButtonLoading(true);
        setTimeout(() => {
          // update active step to next one if verified
          window.scrollTo(0, 0);

          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setButtonLoading(false); // Stop the loading state
        }, 1000); // 0.5 seconds delay
      } else if (otp !== '123456') { // Replace this condition with actual verification logic
        setError(true);
        setVerified(false);
      } else {
        setError(false);
        setVerified(false);
        console.log(`Verification code entered: ${otp}`);
        // Call backend API for verification
      }
      setLoading(false);
    }, 1500); // Simulate validation delay (1.5 seconds)
  };

  const handleSubmit = (event) => {
    // Update this so there is a state for if this has been submitted before and dont run if so
    console.log('its submitting')
    if(activeStep!==3){
      console.log('this is getting called before 3')
      return
    }
    // event.preventDefault();
    const formResponse = validateForm()
    console.log(formResponse)
    if(!formResponse){
      return
    }
    // Submit the form
    const formData = new FormData();
    formData.append('firstName', formValues.firstName);
    formData.append('lastName', formValues.lastName);
    formData.append('email', formValues.email);
    formData.append('phone', formValues.phone);
    formData.append('relationship', formValues.relationship);
    formData.append('notes', formValues.notes);
    formData.append('house-photo', photo);


    // This is old format for the image - you can prolly rever thtis to a normal form.
    for (let pair of formData.entries()) {
      console.log(pair[0] + ': ' + pair[1]);
    }

    if(!service){
      // route to udpate value and append a "this isn't a live client" (or post to diff route)
    }else{
      console.log('Your regular form submission fired')
      // Add form submission route to write into your DB
    }
  };

  // automatically trigger form submission when checkbox is checked
  useEffect(() => {
    // this is causing the ownership validation to trigger
    // see if checkbox is ticked, if things are validated, start form submission.
    if(getTermsAcceptedFlag && activeStep===3 && formValues.relationship){
      const formResult = validateForm();
      if(formResult){
        setLoading(true)
        handleSubmit()
      }
        // Set loading to false after a 2-second timeout
      setTimeout(() => {
        setLoading(false);
      }, 2000); // 2000ms = 2 seconds
    }
  }, [getTermsAcceptedFlag]);

  const handleBlur = (event, nextRef) => {
    const { value } = event.target;

    // If value length is greater than 4 characters, move focus to the next field
    if (value.length > 4 && nextRef.current) {
      nextRef.current.focus();
    }
  };

  return (
    <Box
      component="form"
      encType="multipart/form-data"
      // onSubmit={handleSubmit}
      sx={{
        marginTop: '12px',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '375px',
      }}
      className="w-full h-full"
    >
      <Typography variant="h6" align="left">Enter your details:</Typography>
      
      {activeStep === 1 && (
        <Container maxWidth="xs" className="flex flex-grow h-full flex-col">
          
          {/* First Name Input */}
          <TextField
            label="First Name*"
            name="firstName"
            value={formValues.firstName}
            onChange={handleInputChange}
            fullWidth
            margin='none'
            inputRef={firstNameRef}
            onBlur={(e) => handleBlur(e, lastNameRef)}
            sx={{
              backgroundColor: 'white',
              marginTop: '6px',
              '& .MuiInputLabel-root': {
                zIndex: '1 !important',
                backgroundColor: 'transparent',
              },
              '& .MuiInputLabel-shrink': {
                backgroundColor: 'transparent',
              },
            }}
            error={formErrors.firstName}
          >
          </TextField>
          <FormHelperText
            error
            sx={{
              height: '20px', 
              visibility: formErrors.firstName ? 'visible' : 'hidden',
              marginTop: '0px',
              marginLeft: '20px',
              opacity: formErrors.firstName ? 1 : 0,
              transition: 'opacity 0.3s ease',
            }}
          >
            <span className="text-red-500 text-xs">*This field is required</span>
          </FormHelperText>

          {/* Last Name Input */}
          <TextField
            label="Last Name*"
            name="lastName"
            value={formValues.lastName}
            onChange={handleInputChange}
            fullWidth
            margin='none'
            inputRef={lastNameRef}
            onBlur={(e) => handleBlur(e, emailRef)} // Move focus to next field after blur if length > 4
            sx={{
              backgroundColor: 'white',
              '& .MuiInputLabel-root': {
                zIndex: '1 !important',
                backgroundColor: 'transparent',
              },
              '& .MuiInputLabel-shrink': {
                backgroundColor: 'transparent',
              },
            }}
            error={formErrors.lastName}
          >
          </TextField>
          <FormHelperText
            error
            sx={{
              textAlign: 'left',
              height: '20px', 
              visibility: formErrors.lastName ? 'visible' : 'hidden',
              marginTop: '0px',
              marginLeft: '20px',
              opacity: formErrors.lastName ? 1 : 0,
              transition: 'opacity 0.3s ease',
            }}
          >
            <span className="text-red-500 text-xs">*This field is required</span>
          </FormHelperText>

          {/* Email Input */}
          <TextField
            label="Email Address*"
            name="email"
            type="email"
            value={formValues.email}
            onChange={handleInputChange}
            fullWidth
            margin='none'
            inputRef={emailRef}
            onBlur={(e) => handleBlur(e, phoneRef)}
            sx={{
              backgroundColor: 'white',
              '& .MuiInputLabel-root': {
                zIndex: '1 !important',
                backgroundColor: 'transparent',
              },
              '& .MuiInputLabel-shrink': {
                backgroundColor: 'transparent',
              },
            }}
            error={formErrors.email}
          >
          </TextField>
          <FormHelperText
            error
            sx={{
              height: '20px', 
              visibility: formErrors.email ? 'visible' : 'hidden',
              marginTop: '0px',
              marginLeft: '20px',
              opacity: formErrors.email ? 1 : 0,
              transition: 'opacity 0.3s ease',
            }}
          >
            <span className="text-red-500 text-xs">*This field is required</span>
          </FormHelperText>

          {/* Phone Input */}
          <TextField
            label="Phone Number*"
            name="phone"
            type="tel"
            autoComplete="tel"
            value={formValues.phone}
            onChange={handleInputChange}
            fullWidth
            margin='none'
            inputRef={phoneRef}
            sx={{
              backgroundColor: 'white',
              '& .MuiInputLabel-root': {
                zIndex: '1 !important',
                backgroundColor: 'transparent',
              },
              '& .MuiInputLabel-shrink': {
                backgroundColor: 'transparent',
              },
            }}
            error={formErrors.phone}
          >
          </TextField>
          <FormHelperText
            error
            sx={{
              height: '20px', 
              visibility: formErrors.phone ? 'visible' : 'hidden',
              marginTop: '0px',
              marginLeft: '20px',
              opacity: formErrors.phone ? 1 : 0,
              transition: 'opacity 0.3s ease',
              zIndex: '1 !important',
            }}
          >
            <span className="text-red-500 text-xs">*This field is required</span>
          </FormHelperText>

        </Container>
      )}

      {activeStep === 2 && (
        <SMSVerification handleSubmit={handleSMSVerification} error={error} verified={verified} loading={loading}/>
      )}
      {/* Second form page, relationship and property notes */}
      {activeStep === 3 && (
        <Container maxWidth="xs" className="flex flex-grow h-full pb-12 flex-col justify-center items-center text-center" >
          {/* Property relationship selection */}
          <FormControl
            fullWidth
            variant="outlined"
            margin='none'
            sx={{
              backgroundColor: 'transparent',
              '& .MuiFormLabel-root': {
                zIndex: '1 !important',
                backgroundColor: 'transparent',
              },
            }}
            error={formErrors.relationship} // This controls the error state for the helper text
          >
            <InputLabel id="relationship-label">Relationship to Property*</InputLabel>
            <Select
              labelId="relationship-label"
              id="relationship-select"
              name="relationship"
              label="Relationship to Property*"
              value={formValues.relationship}
              onChange={handleInputChange}
              sx={{
                '& .MuiSelect-select': {
                  textAlign: 'left', // Adjust this to 'left', 'right', or 'center' based on your preference
                },
              }}
            >
              <MenuItem value="Owner">Owner</MenuItem>
              <MenuItem value="Renter">Renter</MenuItem>
              <MenuItem value="Agent">Agent</MenuItem>
            </Select>
            {/* Display error helper text */}
            <FormHelperText
              error
              sx={{
                height: '20px', // Maintain height for the error text
                visibility: formErrors.relationship ? 'visible' : 'hidden',
                marginTop: '0px',
                marginLeft: '22px',
                opacity: formErrors.relationship ? 1 : 0,
                transition: 'opacity 0.3s ease', // Smooth transition for visibility
              }}
            >
              <span className="text-red-500 text-xs">*This field is required</span>
            </FormHelperText>
          </FormControl>

          {/* Property notes / details */}
          <FormControl fullWidth variant="outlined"
          margin='dense'
          >
          <TextField
            label="Notes"
            variant="outlined"
            fullWidth
            multiline
            rows={2}
            value={formValues.notes}
            onChange={handleInputChange}
            placeholder="ex: Property owned by 123 riverwalk LLC, and it is waterfront. (Optional)"
            className="mb-0"
            // Have label shrunken by default so you can display the placeholder.
            InputLabelProps={{shrink: true}}
            sx={{
              backgroundColor: 'transparent',
              marginTop: '0px',
              '& .MuiInputLabel-root': {
                zIndex: '1 !important',
                backgroundColor: 'transparent',
              },
              '& .MuiInputLabel-shrink': {
                backgroundColor: 'transparent',
              },
            }}
          />
          </FormControl>


          <FormControl
            error={getTermsAcceptedFlag === false}
            sx={{ backgroundColor: 'transparent' }}
          >
            <FormControlLabel
              sx={{
                backgroundColor: 'transparent',
              }}
              control={
                <Checkbox
                  checked={getTermsAcceptedFlag}
                  onChange={handleCheckboxChange}
                  sx={{
                    padding: '0 !important',  // Remove padding on the checkbox
                    // '& .MuiSvgIcon-root': {
                    //   fontSize: '1.25rem',  // Adjust the size of the checkbox icon
                    // },
                    '&.MuiCheckbox-root': {
                      color: error ? 'red' : '',  // Set the checkbox color to red when error state is true
                      '&.Mui-checked': {
                        color: error ? 'red' : '',  // Set the checked color to red when error state is true
                      },
                      '&:hover': {
                        backgroundColor: 'transparent',  // Remove hover background if needed
                      },
                    },
                  }}
                />
              }
              label={
                <span className=''>
                  *I agree to the{' '}
                  <NavLink to={{
                    pathname: contractLink,
                    search: window.location.search, // Retains the current URL params
                  }}
                  className="text-blue-500 underline">
                    terms and conditions
                  </NavLink>
                </span>
              }
            />

            <FormHelperText
              error
              sx={{
                height: '20px', // Maintain height for the error text
                visibility: error ? 'visible' : 'hidden',
                marginTop: '0px',
                marginLeft: '18px',
                opacity: error ? 1 : 0,
                transition: 'opacity 0.3s ease', // Smooth transition for visibility
              }}
            >
              <span className="text-red-500 text-xs">*This field is required</span>
            </FormHelperText>
          </FormControl>


          {/* Home photo upload (only display on mobile if we do this at all) */}
          {/* Honestly maybe just remove this, and have the photo submissions handled all through text */}
          {/* <Typography variant="body1" className="text-blue-500 underline">Home photo upload</Typography>
          <IconButton color="primary" aria-label="upload picture" component="label">
            <CameraAltIcon fontSize="large" />
            <input
              accept="image/*"
              capture="environment"
              type="file"
              id="house-photo"
              name="house-photo"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
          </IconButton> */}
        </Container>
      )}


{/* <Container className="flex flex-grow h-full pb-12 flex-col justify-center items-center text-center" > */}

    <Box maxWidth="xs"  sx={{ display: 'absolute', justifyContent: 'space-between', position: 'absolute', bottom: 0, left: 0, right: 0, backgroundColor: 'white', padding: 1 }}>
      <MobileStepper
        variant="progress"
        className="flex !important w-full flex-grow !important"
        steps={steps}
        activeStep={activeStep}
        position="static"
        nextButton={
          activeStep!==3?
          (
          <Button size="small" type='button' onClick={handleNextStep} disabled={activeStep === 4 || buttonLoading} variant='contained'>
            Next
            {buttonLoading?
            <CircularProgress size={16} color="inherit" />
            :
              <ArrowForwardIcon />
            }
          </Button>
          )
          :
          (
          <Button size="small" onClick={handleSubmit} variant='contained' color="primary" disabled={loading}>
            {loading&&(
            <CircularProgress size={16} color="inherit" />
            )
            }
            Submit
          </Button>
          )
        }
        backButton={
          <Button
            size="small"
            onClick={handleBack}
            disabled={activeStep === 1}
            variant="outlined"
            sx={{
              '&.Mui-disabled': {
                borderColor: 'gray !important',  // Gray outline when disabled
                backgroundColor: 'transparent !important',  // Transparent background when disabled
                color: 'gray !important',  // Gray text color when disabled
                cursor: 'not-allowed',  // Prevent cursor change
              },
            }}
          >
            <ArrowBackIcon />
            Back
          </Button>
        }
      ></MobileStepper>
      </Box>      

      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Electronic Signature Agreement</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This checkbox represents an electronic signature. By checking this box, you agree to the terms and conditions of
            <NavLink
              to={{
                pathname: contractLink,
                search: window.location.search, // Retains the current URL params
              }}
              className="text-blue-500 underline ml-1 cursor-pointer"
            >
              Propriety's service contract
            </NavLink>.
          </DialogContentText>
          <Typography variant="body1" className="mt-2 underline">
            Summary of terms:
          </Typography>
          <ul style={{ marginTop: '0px', paddingLeft: '20px', listStyleType: 'disc' }}>
            <li>
              <strong>If we are not successful in saving you money, you owe us nothing.</strong>
            </li>
            <li>
              If we successfully reduce your assessment, you agree to pay {feePercent}% of the first-year savings. If you do not pay after 30 days, this increases to 75% and interest starts to accrue.
            </li>
            <li>If necessary, a $30 filing fee and a $75 appraisal fee may be charged (only if successful).</li>
            <li>
              Our partner firm that uses our software,
              <a
                href="https://aventineproperties.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline ml-1"
              >
                Aventine Properties
              </a>
              , will contact you for case updates.
            </li>
          </ul>
        </DialogContent>

        <DialogActions className='items-center text-center justify-between pt-0 pb-[12px]'>
          <Button onClick={handleCloseDialog} color="primary" variant='outlined'>Disagree</Button>
          <Button onClick={handleAgree} color="primary" variant='contained'>Agree</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default CaptureForm;
