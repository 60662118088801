import axios from 'axios';

let baseURL

// tgest that this works, this could be the issue w/ your sign in
const environment = process.env.REACT_APP_ENV || 'production';
console.log('Environment:', environment);


// if (process.env.REACT_APP_ENV === 'production') { // this is where your post url gets set.
//   // Code to run in development mode
//   baseURL = 'https://api.proprietyrealty.com' // this is your google flask backend atm, need to change to prod EC2
//   // baseURL = 'https://staging.proprietyrealty.com/'
//   // baseURL = 'ec2-18-191-149-71.us-east-2.compute.amazonaws.com'
// } else if (process.env.REACT_APP_ENV === 'staging') { // this is where your post url gets set.
//     // baseURL = 'https://3.15.211.236:8000/'
//     // ec2-3-15-211-236.us-east-2.compute.amazonaws.com
//     baseURL = 'https://staging.proprietyrealty.com/'
//     baseURL = process.env.REACT_APP_API_BASE_URL //TODO remove this
//     baseURL = 'http://localhost:8000'
//   } else {
//     console.log('setting base!')
//     // baseURL = 'https://3.139.190.156:8000/'
//   baseURL = process.env.REACT_APP_API_BASE_URL
//   // baseURL = 'https://staging.proprietyrealty.com/'
//   }

// const environment = process.env.REACT_APP_ENV || 'production';
// console.log('Environment:', environment);
// console.log('REACT_APP_API_BASE_URL:', process.env.REACT_APP_API_BASE_URL);

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Your API base URL
  timeout: 900000000, // Adjust timeout as needed - was 900000 before
  headers: {
    'Content-Type': 'application/json',
    // You may have other default headers here
  },
  referrerPolicy: {
    policy: 'strict-origin-when-cross-origin'
  }
});

export default axiosInstance;