import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';

// Wrap the Message component with React.memo and provide a custom comparison function
const Message = React.memo(({ sender, text, hasTyped, onTypingComplete, msgIndex }) => {
    const isUser = sender === 'user';
    const [displayedText, setDisplayedText] = useState('');
    const [isTyping, setIsTyping] = useState(false);

    useEffect(() => {
        // Clear the displayed text when the text prop changes
        setDisplayedText('');

        if (sender === 'bot' && text && !hasTyped) {
            let charIndex = 0;
            setIsTyping(true);

            const typingInterval = setInterval(() => {
                setDisplayedText((prev) => {
                    const nextChar = text.charAt(charIndex);
                    charIndex++;
                    return prev + nextChar;
                });

                if (charIndex >= text.length) {
                    clearInterval(typingInterval);
                    setIsTyping(false);

                    if (onTypingComplete) {
                        onTypingComplete(msgIndex);
                    }
                }
            }, 12); // Adjust the typing speed

            return () => clearInterval(typingInterval);
        } else {
            setDisplayedText(text);
        }
    }, [sender, text, hasTyped, onTypingComplete, msgIndex]);

    return (
        <div
            className={`p-3 my-2 rounded-lg message-blob ${
                isUser ? 'bg-blue-500 text-white ml-auto max-w-96 w-fit' : 'text-black mr-auto w-full'
            }`}
        >
            <Typography>{displayedText}</Typography>
            {isTyping && <span className="typing-indicator">...</span>}
        </div>
    );
}, (prevProps, nextProps) => {
    // Prevent re-render if only hasTyped changes
    return prevProps.hasTyped === nextProps.hasTyped &&
        prevProps.sender === nextProps.sender &&
        prevProps.text === nextProps.text &&
        prevProps.msgIndex === nextProps.msgIndex;
});

export default Message;
