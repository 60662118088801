import React, { useState, useCallback, useEffect, useRef } from 'react';
import {usePersistedDataStore} from '../store';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import throttle from 'lodash.throttle';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
// import {PropertyInfoTooltip} from './CaseReview';
import {PropertyInfoTooltip} from './MuiTable';
import Tooltip from '@mui/material/Tooltip';
import {AdjustmentCell} from './CaseReview';


// Define calculateFlex function
// IF THE SCREEN WIDTH is 2xl or whatever, should change this to be - less?
const calculateFlex = (headers, columnVisibilityModel, containerRef) => {
    if (containerRef.current) {
      const totalWidth = containerRef.current.clientWidth;
      console.log(totalWidth)
    //   for some reason the -65 was removed from total width calc, changed to 85 and seems better.
      const contentWidth = totalWidth-85; // Adjust as needed
      const totalFlex = headers.reduce((sum, header) => {
        if(header.field === 'actions'){
            return sum
        }
        if (columnVisibilityModel[header.field] !== false) {
          return sum + (header.flex);
        }
        return sum;
      }, 0);
  
      // The calculated headers are off by like 1 px or two as we incrememnt, it adds up over header fields.
      return headers.map(header => ({
        ...header,
        calcWidth: (header.flex || 1) / totalFlex * contentWidth,
      }));
    }
    return headers;
  };

  const SubjectDataGridRow = ({ headers, subject, columnVisibilityModel, updateRefWithSubj, unsavedChangesRef }) => {
    console.log(subject)
    const containerRef = useRef(null);
    const [reviewed, setReviewed] = useState(false);
    const [editingCell, setEditingCell] = useState(null);
    const [editableValue, setEditableValue] = useState('');
    
    const handleResize = useCallback(throttle(() => {
        console.log('resizing');
        setCalculatedHeaders(calculateFlex(headers, columnVisibilityModel, containerRef));
    }, 200), [columnVisibilityModel]);
    // console.log(headers)
    // console.log(columnVisibilityModel)

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);

    // useEffect(() => {
    //     setCalculatedHeaders(calculateFlex(headers, columnVisibilityModel, containerRef));
    // }, [headers, columnVisibilityModel]);

    const [calculatedHeaders, setCalculatedHeaders] = useState(headers);

    const imputedValueCheck = useCallback((header, stringValue) => {
        if (header.field === 'SaleDate') {
            if (stringValue) {
                if (!isNaN(stringValue.getTime())) {
                    const month = stringValue.getMonth() + 1;
                    const day = stringValue.getDate();
                    const year = stringValue.getFullYear();
                    const formattedDate = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
                    return <span>{formattedDate}</span>;
                }
                return;
            }
        } 
        // else if (header.field === 'adj_price') { // if the adj sale price is imputed, then return a red value.
        //     // Pass the handler functions for adding and deleting adjustments down here.
        // //     <AdjustmentCell 
        // //     params={params} 
        // //     savedComps={savedComps}
        // //     selectedAdjustments={miscAdjustments}
        // //     caseObject={caseObject}
        // //     handleAddAdj={handleAddAdjustment}
        // //     subject={true}
        // //     handleDeleteAdj={handleDeleteAdj}
        // //   />
        //     // const adjustmentTooltips = [];
        //     // console.log('its adj price');
        //     // for (const key in miscAdjustments) {
        //     //     if (miscAdjustments[key]?.Values?.[0] !== undefined && miscAdjustments[key].Values[0] !== 1) {
        //     //         console.log('misc adj');
        //     //         console.log(miscAdjustments[key]);
        //     //         const adjustmentData = miscAdjustments[key];
        //     //         const adjustmentValue = Number(adjustmentData.Adjustment) || 0; // Ensure it's a number
                    
        //     //         // Calculate the adjustment
        //     //         const adjustment = adjustmentValue;
        
        //     //         // If the adjustment is not zero, add to the tooltip
        //     //         if (adjustment !== 0) {
        //     //             adjustmentTooltips.push(`${adjustmentData.name}: $${Math.round(adjustment).toLocaleString()}`);
        //     //         }
        //     //     }
        //     // }
        //     // console.log(adjustmentTooltips);
        //     // // Create a tooltip string from the adjustmentTooltips array
        //     // const tooltipContent = adjustmentTooltips.length > 0 ? adjustmentTooltips.join(', ') : 'No adjustments';
        //     // let value = stringValue || '';
        //     // // Render the span and tooltip
        //     // return (
        //     //     <div className='flex items-center justify-end'>
        //     //         {adjustmentTooltips.length > 0 ? (
        //     //             <>
        //     //                 {/* Display tooltip with adjustment details */}
        //     //                 <Tooltip title={tooltipContent}>
        //     //                     <span className='mr-1 text-orange-500'>
        //     //                         $
        //     //                     </span>
        //     //                 </Tooltip>
        //     //                 <span className='font-bold'>
        //     //                     {value}
        //     //                 </span>
        //     //             </>
        //     //         ) : (
        //     //             <span className='font-bold justify-end'>
        //     //                 {value}
        //     //             </span>
        //     //         )}
        //     //     </div>
        //     // );
        // }
        
        // you return an empty string w/ this imputed check for adj sale price of the subject.
        let value = stringValue || '';
        if (stringValue === 0) {
            value = 0;
        }
        if (typeof value === 'string' && value.includes('*')) {
            return (
                <div style={{ color: '#d32f2f' }} className='h-full w-full flex items-center justify-center'>
                {/* ; // Render value in red if it contains an asterisk */}
              <span className='text-red-500 h-full w-full hover:text-blue-500'>{value}</span>
              </div>
            )
        } else {
            // if the type of the value is a number, tolocalestring it
            if (typeof value === 'number') {
                return <span style={{ textAlign: header.align }}>{value.toLocaleString()}</span>;
            }
            return <span style={{ textAlign: header.align }}>{value}</span>;
        }
    }, []);

    const handleUpdateRefWithSubj = useCallback((subject,originalSubj) => {
        updateRefWithSubj(subject,originalSubj);
    }, [updateRefWithSubj]);

    const handleCellDoubleClick = (header) => {
        const rawValue = subject[header.field];
    
        if (header.editable) {
            // if the value is imputed (decimal > 2 digits) then clear it out.
            const shouldClearValue = typeof rawValue === 'number' && rawValue % 1 !== 0 && rawValue.toString().split('.')[1].length > 2;
            setEditingCell(header.field);
            setEditableValue(shouldClearValue ? '' : rawValue); // Clear if decimal has more than 2 digits, otherwise keep value
        }
    };

    const handleSave = (value, force = null) => {
        // If the value is an empty string, revert to the original value
        if (value === '') {
            value = subject[editingCell]; // Revert to original value if blank
        }
    
        // Only update if the new value is different from the current value
        if (value !== subject[editingCell] || force === 'force') {
            // Get the existing unsaved changes
            const currentUnsaved = unsavedChangesRef.current.unsavedSubject || {};
    
            // Create a new object to hold the updated data
            const updatedSubject = { ...currentUnsaved, [editingCell]: value };
    
            // Ensure 'pid' is not duplicated, add it if it does not exist
            if (!updatedSubject.pid) {
                updatedSubject.pid = subject.PropertyInfo.parcel_id;
            }
    
            // Update the ref with the new merged data
            handleUpdateRefWithSubj(updatedSubject, subject);
        }
    
        // Reset the editing state
        setEditingCell(null);
    };

    const handleCancel = () => {
        setEditingCell(null);
    };

    return (
        <div
            className={`items-center flex flex-grow ${subject.RepId === '' ? '' : 'bg-yellow-200'}`}
            ref={containerRef}
            style={{ borderTop: "1px solid #ddd" }}
        >
    {calculatedHeaders.map((header, index) => {
        const rawValue = subject[header.field];
        const valueType = header.type;
        let value = rawValue;
        if (rawValue !== '' && rawValue !== undefined && rawValue !== null) {
            value = header.valueGetter
                ? header.valueGetter(subject[header.field])
                : rawValue;
        }

    const isUnsaved = unsavedChangesRef.current?.unsavedSubject?.[header.field] !== undefined;

    // Determine if the header should use PropertyInfoTooltip
    const shouldRenderPropertyInfoTooltip = header.field === "PropertyInfo";
    // console.log(header)

    if(header.field==='actions'){ // custom rendering for action.
        return(
            <div style={{ minWidth: "55px", maxWidth: "50px", outlineOffset: "-1px" }} key={header.field}>
            <div className="flex items-center justify-center w-12 h-full">
                <div
                    className={`flex items-center justify-center w-5 h-5 rounded-full ${reviewed ? "bg-green-500" : ""}`}
                    onClick={() => setReviewed(!reviewed)}
                >
                    <CheckCircleOutlinedIcon className="hover:cursor-pointer" />
                </div>
            </div>
        </div>
        )
    }
    return (
        <div
            key={header.field}
            className={`MuiDataGrid-cell hover:text-blue-500 ${header.align === "left" ? "MuiDataGrid-cell--textLeft" : ""} ${header.editable ? "MuiDataGrid-cell--editable" : ""}`}
            role="gridcell"
            tabIndex={header.editable ? "0" : "-1"}
            style={{
                width: header.calcWidth,
                minWidth: header.minWidth || "50px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                borderLeft: "2px solid #ddd",
                outlineOffset: "-1px",
                textOverflow: "ellipsis",
                textAlign: header.type === "number" ? "right" : header.align,
                color: isUnsaved ? '#facc15' : 'inherit', // Apply color if unsaved
            }}
            onDoubleClick={() => handleCellDoubleClick(header)}
        >
            {
                editingCell === header.field
                    ? <EditingCell value={editableValue} type={valueType} width={header.calcWidth} dropdownOptions={header.valueOptions} onSave={handleSave} onCancel={handleCancel} />
                    : isUnsaved 
                        ? unsavedChangesRef.current.unsavedSubject[header.field] 
                        : shouldRenderPropertyInfoTooltip 
                            ? <PropertyInfoTooltip
                                address={subject.PropertyInfo.Address}
                                parcelId={subject.PropertyInfo.parcel_id}
                                SDName={subject.PropertyInfo.SDName}
                                SDCode={subject.PropertyInfo.SDCode}
                                town={subject.Town}
                                zip={subject.ZipCode}
                                subject={subject}
                                RepId={subject.RepId}
                                MLSNumber={subject.MLSNumber}
                              />
                            //   Can you move the imputed value check to always occur within the rendercell function?
                            : (header.valueGetter
                                ? imputedValueCheck(header, value)
                                : header.renderCell
                                    ? header.renderCell({ value })
                                    : value || "None")
            }
        </div>
    );
})}

            <div style={{ minWidth: "15px", maxWidth: "15px", outlineOffset: "-1px" }}></div>
        </div>
    );
};

export default SubjectDataGridRow;

const EditingCell = React.memo(({ value, onSave, onCancel, type, width, dropdownOptions }) => {
    const [inputValue, setInputValue] = useState(value);
    const inputRef = useRef(null);
    
        useEffect(() => {
            const handleClickOutside = (event) => {
                 // special handling for onclick function was required here (the input change wasn't working b/c click out upate fires first)
                if (event.target.tagName === 'LI') {
                    onSave(event.target.innerText,'force'); // add force param to force the update to go through / delineate between a regular save event.
                }
                if (inputRef.current && !inputRef.current.contains(event.target)) {
                    onSave(inputValue);
                }
            };
            document.addEventListener('mousedown', handleClickOutside);
            return () => document.removeEventListener('mousedown', handleClickOutside);
        }, [inputValue, onSave]);
    
        const handleChange = (event) => {
            console.log('changing')
            console.log(event.target.value)
            setInputValue(event.target.value);
        };

        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                onSave(inputValue);
            }
            if (event.key === 'Escape') {
                onCancel();
            }
        };
    
        return (
            <div
                ref={inputRef}
                style={{ 
                    width: width || '100%', // Ensure full width if not specified
                    height: '100%', // Full height of parent
                    display: 'flex', // Enable flex for children
                    alignItems: 'center', // Center items vertically
                    padding: 0, // Remove default padding
                    boxSizing: 'border-box', // Include border in element's total width and height
                    position: 'relative', // Ensure the container is positioned for children to expand
                }}
            >
                {type === 'singleSelect' && (
                    <Select
                        value={inputValue}
                        autoFocus
                        fullWidth
                        defaultOpen={true}
                        MenuProps={{ // this needs to be updated to open in the same manner as the grid one. but works fo rnow.
                            anchorOrigin: {
                              vertical: 'top',
                              horizontal: 'center'
                            },
                            transformOrigin: {
                              vertical: 'bottom',
                              horizontal: 'center'
                            }
                          }}
                        sx={{
                            flex: 1, // Ensure TextField expands to full width
                            height: '100%', // Ensure full height of parent container
                            padding: 0, // Remove internal padding
                            margin: 0, // Remove margins
                            '& .MuiOutlinedInput-root': {
                                border: '2px solid #ddd',
                                borderRadius: 0,
                                height: '100%', // Ensure full height of the input
                            },
                            '& .MuiInputBase-input': {
                                paddingTop: 0,
                                paddingBottom: 0,
                                height: '100%', // Ensure full height of the input
                                boxSizing: 'border-box', // Include padding and border in height calculation
                            },
                            // Optional: Override specific styles if needed
                            '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
                                paddingTop: 0,
                                paddingBottom: 0,
                            },
                        }}
                    >
                        {dropdownOptions.map((option) => (
                            <MenuItem key={option} value={option}
                            >
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                )}
                {type === 'number' && (
                    <TextField
                        type="number"
                        value={inputValue}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        autoFocus
                        fullWidth
                        sx={{
                            flex: 1, // Ensure TextField expands to full width
                            height: '100%', // Ensure full height of parent container
                            padding: 0, // Remove internal padding
                            margin: 0, // Remove margins
                            '& .MuiOutlinedInput-root': {
                                border: '2px solid #ddd',
                                borderRadius: 0,
                                height: '100%', // Ensure full height of the input
                            },
                            '& .MuiInputBase-input': {
                                paddingTop: 0,
                                paddingBottom: 0,
                                height: '100%', // Ensure full height of the input
                                boxSizing: 'border-box', // Include padding and border in height calculation
                            },
                            // Optional: Override specific styles if needed
                            '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
                                paddingTop: 0,
                                paddingBottom: 0,
                            },
                        }}
                    />
                )}
                {type === 'text' && (
                    <TextField
                        type="text"
                        value={inputValue}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        autoFocus
                        fullWidth
                        InputProps={{
                            sx: { 
                                padding: 0, // Remove padding
                                height: '100%', // Ensure full height
                                boxSizing: 'border-box', // Include padding and border in height calculation
                            }
                        }}
                    />
                )}
            </div>
        );
    }
    );
    