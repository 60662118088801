import React from 'react';
import SortableTable from '../SortableTable';
import { DataGridPro, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport, GridToolbarQuickFilter,
    GridToolbarExportContainer, GridCsvExportMenuItem, gridFilteredSortedRowIdsSelector, gridVisibleColumnFieldsSelector, useGridApiContext } from '@mui/x-data-grid-pro';
// idk why use this sortable table component?


// This sortable table component is basically a precursor to MUI's datagrid.

const VisualizationTable = ({ headers, rows, MuniCode }) => {
    console.log(headers)
    console.log(rows)
    return (
        <div className="flex-1 overflow-hidden">
        <div className="h-full max-h-[77vh] overflow-y-auto">

            <DataGridPro
                columns={headers}
                rows={rows}
                pagination // Enables pagination
                pageSize={100} 
                // this is TEMPORARY brennan needs to add an indexed ID
                // getRowId={(row) => row.ParcelID || row.Address}
                slots={{
                    toolbar: () => (
                        <div className='toolbar-buttons'>
                        <GridToolbarColumnsButton
                        // Custom thing to define which fields you can actually see or not
                            // getTogglableColumns={getTogglableColumns(headers)}
                        />
                        <GridToolbarFilterButton />
                            <GridToolbarExportContainer>
                                <GridCsvExportMenuItem options={{fileName: `${MuniCode}_sales_time_trend`, separator: ','}} />
                            </GridToolbarExportContainer>
                        <GridToolbarQuickFilter
                        />
                    </div>
                    )
                }}
            />
        </div>
    </div>
    );
};

export default React.memo(VisualizationTable);