import { usePersistedDataStore } from '../components/store';
import useUpdateDB from './updateDB.js';
import { useNavigate, useSearchParams, useLocation, NavLink } from 'react-router-dom';
import React from 'react';
import _ from 'lodash';

const DBConfirmModal = ({ 
  inventoryUpdates, 
  confirmModal, 
  handleConfirmCancel, 
  handleCloseModal,
  onDBUpdateSuccess // Pass the callback from the parent
}) => {
  const updateDB = useUpdateDB();

  if (!confirmModal) {
    return null; // Render nothing if confirmModal is false
  }

  const originalSubject = inventoryUpdates.originalSubject;
  const unsavedRows = inventoryUpdates.unsavedRows || [];
  const rowsBeforeChange = inventoryUpdates.rowsBeforeChange || {};

  const before = Object.keys(originalSubject).length > 0 
    ? [originalSubject, ...Object.values(rowsBeforeChange)] 
    : [...Object.values(rowsBeforeChange)];

  const after = Object.keys(inventoryUpdates.unsavedSubject).length > 0 
    ? [inventoryUpdates.unsavedSubject, ...Object.values(unsavedRows)] 
    : [...Object.values(unsavedRows)];

  const afterBackend = _.cloneDeep(after);
  afterBackend.map((update) => {
    if (update.View) {
      for (const key in update) {
        if (key === "View") {
          if (update[key] === "None") {
            update['Waterfront'] = 0;
            update['Waterview'] = 0;
            delete update.View;
          } else {
            update[update.View] = 1;
            delete update.View;
          }
        }
      }
    }
  });

  const handleConfirm = () => {
    updateDB({ confirmed: true, handleConfirmCancel, updatedValues: afterBackend })
      .then((response) => {
        // Will this work if it is a stream instead of a regular response?
        
        // Pass the response to the parent component
        onDBUpdateSuccess(response);
        handleConfirmCancel({}, true); // Close the modal
      })
      .catch((error) => {
        console.error('Error while updating DB:', error);
      });
  };

  return (
    <div className="fixed inset-0 p-4 flex justify-center items-center w-full h-full z-50 bg-gray-600 bg-opacity-50">
      <div className="bg-white p-6 rounded-md shadow-lg max-w-lg w-full">
        <h2 className="text-xl font-semibold mb-4">Review Changes</h2>
        <div className="w-full">
          <div className="flex font-bold border-b-2 pb-2">
            <div className="w-1/3">Address</div>
            <div className="w-2/3">Values</div>
          </div>
          {before.map((beforeRow, index) => {
            const afterRow = after[index];
            return (
              <div key={index} className="flex border-b py-2">
                <div className="w-1/3">{beforeRow.PropertyInfo?.Address || 'N/A'}</div>
                <div className="w-2/3 space-y-2">
                  {Object.keys(beforeRow).map((key) => {
                    const beforeValue = beforeRow[key];
                    const afterValue = afterRow[key];
                    if (afterValue) {
                      return (
                        <div key={key} className="flex">
                          <div className="flex-1">{afterValue || 'N/A'}</div>
                          <div className="text-gray-500 flex-1">(orig: {beforeValue || 'N/A'})</div>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            );
          })}
        </div>

        <div className="flex justify-end mt-6">
          <button
            type="button"
            onClick={handleConfirmCancel}
            className="px-4 py-2 mr-2 bg-gray-200 rounded-md"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleConfirm}
            className="px-4 py-2 bg-green-500 text-white rounded-md"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default DBConfirmModal;
