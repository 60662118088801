import axiosInstance from '../axiosConfig.js'
import { toast } from 'react-toastify';
import _ from 'lodash';


// lol ultimately didn't really need to extrac this into its own fucntion after the refactor huh..
   export async function saveComps(saveObject) { 
      try {
        console.log('starting to save')
        console.log('sending in this obj:', saveObject)
        const saveCompsResponse = await axiosInstance.post('/update_comparables', saveObject)
        console.log(saveCompsResponse)
        toast.success('Comparables saved successfully.')
        return saveCompsResponse.data
        // can hit a return to pass back some sucess state.
      }
      catch (error) {
        console.error(error)
        toast.error(`There was an error saving your comparables. ${error}`)
        // passback an error state (can jusjt use toasts.)
      }      
}

// // (single function approach, don't need to also have one in app top level)
// // async function saveOneComp(Comparables) { // write just one comp into DB as you work through.
      
// //     // the rest of function post response -- you do need somehtin glike this still I think.
// // dumb but necessary :(
// //     const updatedNegotiationObj = {
// //         ...getNegotiationObj,
// //         cases: getNegotiationObj.cases.map((caseObj, index) => 
// //           index === comp 
// //             ? {
// //                 ...caseObj,
// //                 Comps: resultObject.Cases[subjId]
// //               }
// //             : {...caseObj}
// //         )
// //       };

// //       console.log(updatedNegotiationObj)

// //       setNegotiationObj(updatedNegotiationObj)
// //       setCourtComps(updateComps)
// //       // getReviewedComps(updatedNegotiationObj)
      
// //       const url = new URL(location.pathname+location.search, window.location.origin);
// //       const searchParams = new URLSearchParams(url.search);
// //       searchParams.set('view', 'court');
// //       const newUrlString = `${url.pathname}?${searchParams.toString()}`;
// //       navigate(newUrlString);

// //     setLoading(false)
// //     // console.log(resultObject);
// //   }
