import React, { memo } from 'react';
import Plot from 'react-plotly.js';

const GraphPlot = memo(({plot}) => {
    // Your component logic here
    return (
        <Plot
        data={plot.data}
        layout={plot.layout}
        transforms={[{
          type: 'filter',
          target: 'y',
          operation: '>',
          value: 100
        }]}
        style={{ width: '100%', height: '90%' }}
        useResizeHandler={true}
        config={{ displaylogo: false, responsive: true, datarevision: plot, displayModeBar: true }}
      />

    );
});

export default GraphPlot;