import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useSearchParams, useLocation, NavLink } from 'react-router-dom';
import {useCompStore, useAdminSettingsStore, useLocalDataStore, usePersistedDataStore} from '../store.js';
import { toast } from 'react-toastify';
import axiosInstance from '../../axiosConfig.js'
import _, {isEqual} from 'lodash';
import Button from '@mui/material/Button';
import HomeIcon from '@mui/icons-material/Home';

function CompDashboard({setLoading, nyDataObj, setWeightWidget, yearDropdownOptions, globalCompRef, handleUpdateStateCallback}){
  // setloading can be deleted once your save comps is fixed.
    const setCompSheet = usePersistedDataStore((state) => state.setCompSheet) // set comps
    const getCompSheet = usePersistedDataStore((state) => state.compSheet)
    const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj)
    const setNegotiationObj = usePersistedDataStore((state) => state.setNegotiationObj)
    const getCaseNotes = usePersistedDataStore((state) => state.caseNotes);
    const setCaseNumber = usePersistedDataStore((state) => state.setCaseNumber)
    const getFetchLoad = usePersistedDataStore((state)=> state.fetchLoad)
    const setFetchLoad = usePersistedDataStore((state)=> state.setFetchLoad)
    const getOptimizedComps = usePersistedDataStore((state)=> state.optimizedComps)
    const setOptimizedComps = usePersistedDataStore((state)=> state.setOptimizedComps)
    const setPopup = useAdminSettingsStore((state) => state.setPopup)
    const setCourtMunis = usePersistedDataStore((state)=> state.setCourtMunis)
    const getDefaultComps = useAdminSettingsStore((state) => state.defaultComps)
    const getReviewPage = useLocalDataStore((state)=> state.reviewPage)
    const setReviewPage = useLocalDataStore((state)=>state.setReviewPage)
    const [displayCustom, setDisplayCustom] = useState(false)
    const navigate = useNavigate();
    const [addedPID, setAddedPID] = useState(null)
    const [addIndex, setAddIndex] = useState(null)
    const getCourtComps = usePersistedDataStore((state) => state.courtComps);
    const setCourtComps = usePersistedDataStore((state) => state.setCourtComps);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const comp = parseInt(queryParams.get('comp')) - 1 || 0 ; // Subtract 1
    const MuniCode = queryParams.get('MuniCode') || 'All';
    const municipality = queryParams.get('municipality') || 'All';
    const county = queryParams.get('county') || 'All';
    const village = queryParams.get('village') || 'All';
    const courtDate = queryParams.get('CourtDate') || '';
    const taxYear = queryParams.get('TaxYear') || getNegotiationObj.TaxYear; // updating taxyear updates whole obj. Analyze refactors to see if performance issue in future.
    const view = queryParams.get('view') || 'regular';
    const [upcomingCourtDates, setUpcomingCourtDates] = useState([])


    // this component is probably inefficient because of the work it does on each render

    
    const stillLoading = useMemo(() => {
      // Default values
      console.log('updating loading val')
      // Initialize to true if getCompSheet is empty
      if (getCompSheet.length === 0) return true;
    
      // Logic to determine loading state
      return (
        getCompSheet.length < getNegotiationObj.cases.length
      );
    }, [getCompSheet]);

    async function handleFlagNoSCAR(){
      console.log(getCompSheet[comp].parcel_id[0])
      try{
      const optimizeResponse = await axiosInstance.post(`/flag_case_scar_status`, { // passing in the indexes for pagination
                  Subject: getCompSheet[comp].parcel_id[0],
                  TaxYear: getNegotiationObj.TaxYear,
                  filingStatus: 0,
                });
        toast.success(`Flagged case to not file at Scar.`, {
          position: 'top-left',
          className: 'mt-10',
          autoClose: 3000,
      });
    }catch{
      toast.error('some Error occured', {
        position: 'top-left',
        className: 'mt-10',
        autoClose: 3000,
      })
    }
  }   

    // old settlement update function
    function updateOffers(e) {
      // If settled === true, DON'T UPDATE:
      const { id } = e.target;
      const inputValue = e.target.value;
          const updatedNegotiation = { // clone the outside obj, and the interior cases array.
            ...getNegotiationObj,
            cases: [
              ...getNegotiationObj.cases
            ]
          }
          // Ensure that the element at comp exists or create it
          if (!updatedNegotiation.cases[comp]) { // create the object within the array.
            // console.log('Creatng new Obj - this case has no prior val')
            updatedNegotiation.cases[comp] = {settlement: ''};
          }
          if(updatedNegotiation.cases[comp].settled){
            return
          }else{
          if (inputValue === '') { // sets it blank without doing the comma conversion if the val is empty
            updatedNegotiation.cases[comp][id]=''; // or updateStateWithNumericValue(null);
            console.log(updatedNegotiation)
            setNegotiationObj(updatedNegotiation)
            } else {
            // Remove commas before updating the state
            // console.log(getCompSheet[comp].parcel_id[0])
            updatedNegotiation.cases[comp].PID = getCompSheet[comp].parcel_id[0]
            const numericValue = inputValue.replace(/[^0-9.]/g, '')
            updatedNegotiation.cases[comp][id] = numericValue
            setNegotiationObj(updatedNegotiation)
            console.log(updatedNegotiation)
          }
          }
    }

    function handleClear(){ // removes your states for offers and comps.
      const updatedNegotiation = { // clone the outside obj, create the interior cases array.
        ...getNegotiationObj,
        cases: []
      }
      setCompSheet('') // remove the compsheet
      setFetchLoad(false)
      updatedNegotiation.municipality = 'All'
      updatedNegotiation.county = 'All'
      updatedNegotiation.village = 'All'
      updatedNegotiation.MuniCode = null
      updatedNegotiation.courtDate = 'All'
      updatedNegotiation.CourtRun = 0
      updatedNegotiation.courtDateSelectedMuni = null
      // udpatedNegotiation.
      setCaseNumber(0)
      setOptimizedComps([])
      setReviewPage(false)
      setCourtMunis([])
      setNegotiationObj(updatedNegotiation) // remove all offers
      setCourtComps([])

      const url = new URL(location.pathname+location.search, window.location.origin);
      const searchParams = new URLSearchParams(url.search);
      // searchParams.set('view', 'regular');
      const newUrlString = `${url.pathname}?${searchParams.toString()}`;
      navigate(newUrlString);
      // SetCourt date
    }

    function setSettled(value){ // the .settlement is inherently set, becasuse it gets locked when this function runs.
      console.log(getNegotiationObj)
      const updatedSettlement = { // clone the outside obj, and the interior cases array.
        ...getNegotiationObj,
        cases: [...getNegotiationObj.cases]
        }

        if(updatedSettlement.cases[comp].settlement>getCompSheet[comp].IFMV[0]){
        toast.error('Settlement higher than current value!', { // display warning toast.
          position: toast.POSITION.TOP_RIGHT,
        });
        updatedSettlement.cases[comp].settlement = '' // and reset the settlement value to zero
      }else{
        updatedSettlement.cases[comp].settled=value
        // Should probably round the updated settlement vale to be
        console.log(getCompSheet[comp].RAR[0])
        console.log(updatedSettlement.cases[comp].settlement)
        // Calculate the Settled AV using RAR
        updatedSettlement.cases[comp].settledAV=updatedSettlement.cases[comp].settlement*getCompSheet[comp].RAR[0]
        
        // Set the type equal to 'S' -- may need to amend this to ST.
        updatedSettlement.cases[comp].type = 'ST'
      }
        setNegotiationObj(updatedSettlement)
    }

    function clearInputs(){ // eliminate settlement value
      const updatedNegotiation = { // clone the outside obj, and the interior cases array.
        ...getNegotiationObj,
        cases: [
          ...getNegotiationObj.cases
        ]
      }
      // Ensure that the element at comp exists or create it
      if (!updatedNegotiation.cases[comp]) { // create the object within the array.
        console.log('Creatng new Obj - this case has no prior val')
        updatedNegotiation.cases[comp] = {minOffer:'', maxOffer:'', settlement:'', settledAV:'', settled:false, type: ''};
      }
      updatedNegotiation.cases[comp].settlement = ''
      updatedNegotiation.cases[comp].settledAV = null
      updatedNegotiation.cases[comp].type = ''
      setNegotiationObj(updatedNegotiation)
    }

    return(
      <div className={`relative testing inline overflow-clip min-h-[400px]`}>
          {getCompSheet.length > 0 &&
          <div id='sticky' className="sticky flex justify-between items-center border-b-2 border-gray-500">
              <div className="flex flex-col my-1 gap-3 sm:flex-row sm:items-center ">
                {/* Breadcrumbs below */}
                <nav>
                  <ol className="flex items-center gap-1">
                    <li className='flex'>

                      <NavLink to="/table" className='flex items-center justify-center mr-1 mt-0.5' onClick={()=>handleClear()}>
                      {/* display house if on review. */}
                      {getReviewPage?
                        <HomeIcon className="text-gray-500" />
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#65758B" className="w-5 h-5">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                        </svg>
                      }
                      </NavLink>
                    </li>
                    {/* Breadcrumb display - County / Muni / SCAR ER # */}
                    {
                    courtDate!=='' ?
                      <li className='flex'> {courtDate} - <span className={'text-primary ml-1'}> {MuniCode + ' ('+taxYear+')'}</span>
                      </li>
                    :
                    null
                    }

                    {/* Breadcrumb for county / muni */}
                    {MuniCode!=='All' && courtDate === ''?
                      <div className='flex text-center items-center'>
                        <li id='text' className="flex text-[#1976d2] font-medium mt-1" style={{ lineHeight: '1' }}>
                          {MuniCode + ' (' + taxYear + ')'}
                        </li>
                      </div>
                      :
                      null
                    }
                  </ol>
                </nav>
              </div>
              <div className="flex items-center gap-2">
                <Button
                  variant={getReviewPage ? 'contained' : 'outlined'} // Use 'contained' if active, 'outlined' if inactive
                  onClick={() => {handleUpdateStateCallback();setReviewPage(!getReviewPage)}} // Toggle the review page
                  sx={{ padding: '2px 4px' }}
                >
                  Summary View
                </Button>
              </div>
          </div>
          }

    </div>
)
}export default CompDashboard
