import React, { useState, useRef, useEffect } from 'react';
import OtpInput from 'react-otp-input';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckIcon from '@mui/icons-material/Check'; // use this icon to place inside the inputs if verified = true

function SMSVerificationForm({ handleSubmit, loading, error, verified }) {
  const [otp, setOtp] = useState('');
  useEffect(() => {
    if (verified) {
      setOtp(''); // Clear the OTP input when verified is true
    }
  }, [verified]);

  const handleChange = (otp) => {
    if (verified) return; // Prevent changes if verified is true
    setOtp(otp);
    if (otp.length === 6) {
      const input = document.querySelector('.otp-container input');
      input.blur()
      handleSubmit(otp);
    }
  };

  // this gets you back to the first input if it starts trying to validate.
  useEffect(() => {
    if (error && !loading) {
      setOtp(''); // Clear OTP input values
      const firstInput = document.querySelector('.otp-container input');
      if (firstInput) {
        firstInput.focus();
      }
    }
  }, [loading, error]);

  const handleResendCode = () => {
    console.log('Resend code triggered');
    // Add logic to trigger code resend (e.g., call an API endpoint)
  };

  return (
    <Container maxWidth="xs" className="flex flex-grow h-full pb-12 flex-col justify-center items-center text-center" >
      <Typography variant="h5" align="center" gutterBottom>
        SMS Verification
      </Typography>
      <Box display="flex" justifyContent="center" className='mb-2'>
        <OtpInput
          value={otp}
          onChange={handleChange}
          numInputs={6}
          isInputNum
          containerStyle="otp-container"
          inputStyle={{
            width: '2.5rem',
            height: '2.5rem',
            margin: '0 0.5rem',
            fontSize: '1.5rem',
            borderRadius: 4,
            border: verified ? '2px solid green' : (error ? '1px solid red' : '1px solid #ccc'),
            textAlign: 'center',
          }}
          renderInput={(props) => (
            <div style={{ position: 'relative' }}>
              <input
                {...props}
                disabled={verified}
                type="tel"  // Ensures the numeric keypad is triggered
                inputMode="numeric"
              />
              {/* Show the checkmark icon inside the input field when verified */}
              {verified && (
                <CheckIcon
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    fontSize: '1.5rem',
                    color: 'green', // Green color to match the border
                  }}
                />
              )}
            </div>
          )}
        />
      </Box>
      
      {/* CHECK IF THE MARGIN OF THIS IS WHAT U WANT */}
      {/* Update this to display verifying here */}
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" className='mb-2'>
          <CircularProgress size={20} />
          <Typography variant="body2" align="center" className='ml-2'>
            Validating
          </Typography>
        </Box>
      ) :(verified ? (
          <Typography variant="h6" className="text-green-600 mt-12">
            <CheckCircleIcon style={{ verticalAlign: 'middle', marginRight: '8px' }} />
            Verified
          </Typography>
        ) : 
       error && !verified ? (
        <Typography variant="body2" color="error" align="center" gutterBottom>
          The verification code does not match
        </Typography>
      ) : (
        <div className='h-[24px]'></div>
      ))}
      <Typography variant="body2" align="center">
        {!verified ? (
          <>
            Didn't receive a code?{' '}
            <Link href="#" onClick={handleResendCode}>
              Resend code
            </Link>
          </>
        ):null}
      </Typography>
      <Box mt={2}>
      </Box>
    </Container>
  );
}

export default SMSVerificationForm;
