import React, { useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';
import CaptureForm from './CaptureForm';


function ResultsDisplay({ data, handleBack, feePercent}) {
  const currentParams = new URLSearchParams(window.location.search);
  const formStarted = useMemo(() => currentParams.get('formStarted') === 'true', [window.location.search]);
  const [isFormVisible, setIsFormVisible] = useState(formStarted);
  const [isLoading, setIsLoading] = useState(false);

  const handleReduceTaxesClick = () => {
    setIsLoading(true);
    
    // Always create a new URLSearchParams object to ensure it's current
    const currentParams = new URLSearchParams(window.location.search);
    
    // Modify the URL parameters
    currentParams.set('formStarted', 'true');
    
    // Update the URL without refreshing the page
    const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
    window.history.pushState(null, '', newUrl);
    
    // Simulate the delay before setting the state
    setTimeout(() => {
      setIsLoading(false);
      setIsFormVisible(true);
    }, 500); // Half-second delay
  };
  
    return (
      <>
      {/* THE results top paper */}
        <Paper elevation={3} className="p-4 mt-1 relative">
          {/* Button container */}
          <div className='absolute top-0 left-0'>
            <Button onClick={handleBack} className="items-center flex "
            startIcon={<ArrowBackIcon className='mb-0.5 mr-[-5px]'/>}
            >
              <div className=''>
                Search Again
              </div>
            </Button>
          </div>

          <Typography variant="h5" className="mb-2 mt-4 text-center">
            {data.address}
          </Typography>
          {/* Tax savings header*/}
          <Typography variant="h6" className="mb-2 flex-col text-center">
            <div>
              <strong> You overpay your taxes by: </strong>
            </div>

            <div className='text-green-600'>
              <strong>${data.annualOverpayment} each year </strong>
            </div>
          </Typography>

          {!formStarted && (
            <>
          {/* These two middle ones should be bulleted i think */}
          <Typography variant="body2" className="mb-2">
            Based on our data, <strong>{data.overpaymentPercent}%</strong> of homeowners overpay their taxes in {data.municipality}. 
          </Typography>
          <Typography variant="body2" className="mb-4">
            {/* To illustrate how important this is: */}
            If you had invested this overpayment over the life of a 30y mortgage you would have an extra <strong>${data.cumulativeOverpayment}</strong>.
          </Typography>
          </>
          )}
          <Typography variant="body2" className="mb-2 text-center underline">
            <strong>Stop paying extra tax for no reason.</strong>
          </Typography>


          
          {/* Center the "Reduce your taxes" button */}
          {!isFormVisible ? (
            <div className='text-center'>
              <Button
                variant="contained"
                color="primary"
                onClick={handleReduceTaxesClick}
                disabled={isLoading}
              >
            {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Reduce your taxes'}
          </Button>
          </div>
        ) : (
          <CaptureForm feePercent={feePercent} />
        )}
        </Paper>



      </>
    );
}

export default ResultsDisplay;
