import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function FAQSection({feePercent=50}) {
  // Sample FAQ data array
  
const faqData = [
  {
    question: 'How do we know this?',
    answer: 'Propriety is a goverment valuation software. We help the goverment to value homes to assess property taxes, and our models constantly scan the properties in our database to identify homeowners that can save money. To date, we have produced evidence-based valuations for 1.5+ million parcels.'
  },
  {
    question: 'Can you save me money?',
    answer: 'We do have a service where we reduce homeowners property taxes, if you enter your address, and it informs you that you are overpaying your taxes, most likely yes we can.'
  },
  {
    question: 'How long does this take?',
    answer: (
      <>
        This process varies depending on the county. Typically, cases resolve within 12 months from the filing deadline. To find a full list of counties and timelines, please see our comprehensive list{' '}
        <a href="/counties-timelines" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
          here
        </a>.
      </>
    )
  },  
  {
    question: 'Is there a fee?',
    answer: `As a part of our commitment to fair and equitable property taxes, we only ever charge a fee if we are able to save you money. If we reduce your property taxes, our fee is ${feePercent}% of your savings (for the first year only). If we are unable to save you money, there is no fee.`
  },
  {
    question: 'Is the fee charged more than once?',
    answer: "No. The tax savings will continue until the government decides to re-assess all properties, but our fee is only charged once for the first year's savings."
  },
  {
    question: 'How are property assessments and taxes related?',
    answer: 'Your property assessment is one component of your property taxes. The other component is the tax rate. To save you money on taxes, we reduce the property assessment. Tax rates fluctuate, and are set by the munciipality.'
  },
  // Add more FAQs as needed
];
return (
  <Container maxWidth="" className='mt-4 px-0'>
  <Accordion defaultExpanded>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}  className=''>
      <Typography variant="h5" gutterBottom>
        Frequently Asked Questions
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      {faqData.map((item, index) => (
        <Accordion key={index} style={{ marginBottom: '1rem' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">{item.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{item.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </AccordionDetails>
  </Accordion>
</Container>
);
}

export default FAQSection;
