import React, { useMemo } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useDemoData } from '@mui/x-data-grid-generator';
import { usePersistedDataStore, useLocalDataStore } from '../store';
import { useNavigate } from 'react-router-dom';

const CaseNavigation = ({ address, compNumber }) => {
    const setReviewPage = useLocalDataStore((state)=> state.setReviewPage)
    const navigate = useNavigate();
    const handleClick = () => {
        // Construct the new URL with the 'comp' parameter set to the value of 'number'
        // const url = new URL(`https://www.google.com/maps/place/${address}`);
        const params = new URLSearchParams(window.location.search);

        // Set or update the 'comp' parameter with the provided number
        params.set('comp', compNumber);

        // Construct the new URL with updated parameters
        const newUrl = `${window.location.pathname}?${params.toString()}`;
        setReviewPage(false);
        // Navigate to the new URL
        navigate(newUrl);
    };

    return (
        <div>
            <span onClick={handleClick} style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}>
                {address}
            </span>
        </div>
    );
};

// we are updating the getNegotiationObj value in the review page useffect, causing this guy to rerender.

// CREATE THE ROWS AND HEADERS BEFORE RENDERING THIS COMPONENT TO PREVENT THE VISUAL STUTTER.
export default function SummaryCaseReviewTable({handleCellClick, selectionModel}) {
    // IF THE USER IS IN HERE, CALL YOUR FUNCTION TO GET DATA CONTINUOUSLY.
    // DO THAT IF THE VALUE IS TRUE.

    const handleSelectionChange = (newSelection) => {
      handleCellClick(newSelection);
    };

    const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj);

    const headers = [
        { // Prop info - address, sd, parcel id
            align: "left",
            editable: false,
            field: "Address",
            headerName: "Address",
            minWidth: 120,
            flex: 1, // Allows the column to grow and shrink as needed
            // valueGetter: (params) => params.Address, // updated this so you can search and filter by address (use value getter for this function)
            renderCell: (params) => {
              // const ImgLink = await GetCompPhoto(params.row.parcel_id); // Fetch the image link
              return (
                <CaseNavigation
                  address={params.row.Address}
                  compNumber={params.row.Comp}
                  // imgLink={ImgLink} // Pass the image link to the tooltip
                />
              );
            }
          },
          { // PID
            field: "PID",
            headerName: "PID",
            type: 'string',
            align: "left",
            flex: 0.6, // Adjusted based on the old width of 100
          },
          { // SCARID
            field: "SCARID",
            headerName: "SCAR Id",
            type: 'string',
            align: "left",
            flex: 0.6, // Adjusted based on the old width of 100
          },
          { // Assessment
            field: "AssessmentTown",
            headerName: "Assessment",
            type: 'string',
            align: "left",
            valueGetter: (value) => {
              if (!isNaN(value)) { 
              return Number(value.toFixed(0)).toLocaleString() || '';
              }
            },
            flex: 0.6, // Adjusted based on the old width of 100
          },
          { // IFMV
            field: "IFMVTown",
            headerName: "IFMV",
            type: 'string',
            align: "left",
            valueGetter: (value) => {
              if (!isNaN(value)) { 
                return `$${Number(value).toLocaleString()}` || ''; // Format with $ and localize
            }
            },
            flex: 0.6, // Adjusted based on the old width of 100
          },
          { // Market Value
            field: "MarketValue",
            headerName: "Value",
            type: 'string',
            align: "left",
            renderCell: (params) => {
            const isSettled = params.row.Settled; // Access the 'settled' field
            const marketValue = params.row.MarketValue; // Get MarketValue
            const scarValue = params.row.SCARDeterminationValue/params.row.RAR; // Get SCARDeterminationValue

            // Determine which value to display
            const valueToDisplay = isSettled ? scarValue : marketValue;

            if (!isNaN(valueToDisplay)) { 
                return `$${Math.round(Number(valueToDisplay)).toLocaleString()}`; // Format with $ and localize
            }
            return '';
          },
          flex: 0.6, // Adjusted based on the old width of 100
          },
          { // Pct Reduction
            field: "pctReduction",
            headerName: "Pct Reduction",
            type: 'number', // Type can be number for sorting purposes
            align: "left",
            renderCell: (params) => {
              // console.log(params)
                const isSettled = params.row.Settled; // Access the 'settled' field
                const baseValue = params.row.IFMVTown; // Get MarketValue
                const scarValue = params.row.SCARDeterminationValue/params.row.RAR; // Get SCARDeterminationValue
                const marketValue = params.row.MarketValue; // Get MarketValue
    
                const pctReduction = isSettled ? ((baseValue - scarValue) / baseValue) * 100 : 0;
                const formattedPctReduction = pctReduction < 0 ? 0 : pctReduction;

                // Determine the color based on the value
                let color;
                if (formattedPctReduction > 0.25) {
                    color = 'green'; // Green for values greater than 0.25
                } else if (formattedPctReduction < 0.24) {
                    color = 'red'; // Red for values less than 0.24
                } else {
                    color = 'black'; // Default color for values in between
                }
        
                return (
                    <span style={{ color: color }}>
                        {formattedPctReduction.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%
                    </span>
                ); // Format with % sign and two decimal places
            },
            flex: 0.6, // Adjusted based on the old width of 100
          },
          { // your offer.
            field: "OfferValue",
            headerName: "Offer",
            type: 'number',
            align: "left",
            flex: 0.6,
          },
          { // rep id
            field: "RepID",
            headerName: "Rep ID",
            type: 'string',
            align: "left",
            flex: 0.6,
          },
        {
            field: "status",
            headerName: "Status",
            type: 'string',
            align: "left",
            renderCell: (params) => {
                const isSettled = params.row.Settled; // Access the 'settled' field from the row
    
                return (
                    <span style={{ 
                        fontWeight: isSettled ? 'normal' : 'bold', // Bold if "Open", normal if "Settled"
                        color: isSettled ? 'green' : 'inherit' // Green if "Settled"
                    }}>
                        {isSettled ? "Settled" : "Open"}
                    </span>
                );
            },
            flex: 0.6,
        },
    ];

    // Add in a recent sale flag
    const createRows = (cases) => {
        // Assuming all arrays within `comps` have the same length, we can use any of the arrays, e.g., comps.Address.length
        const length = cases.length;
        const validStatuses = ['S', 'SD', 'ST', 'W', 'NM', 'AH'];
        return Array.from({ length }, (_, index) => {
            const currentCase = cases[index]; // Access the current case object
            return {
                id: index,
                Comp: index + 1, // Index starting from 1 for user readability
                Address: currentCase.Address,
                PID: currentCase.PID,
                Town: currentCase.Town, // Removed index
                ZipCode: currentCase.ZipCode, // Removed index
                AssessmentTown: currentCase.AssessmentTown, // Removed index
                OfferValue: currentCase.OfferValue || null,
                IFMVTown: currentCase.IFMVTown, // Removed index
                SCARID: currentCase.SCARIndexNumber, // Removed index
                MarketValue: currentCase.SubjectMarketValue,
                pctReduction: currentCase.PctReduction*100,
                SalePrice: currentCase.SalePrice || null, // Removed index
                SaleDate: currentCase.SaleDate || null, // Removed index
                RAR: currentCase.RAR, // Removed index
                RepID: currentCase.RepID, // Removed index
                // this status field for if its settled or not
                SCARDeterminationAction: currentCase.SCARDeterminationAction, // Removed index
                SCARDeterminationValue: currentCase.SCARDeterminationValue, // Removed index
                Settled: validStatuses.includes(currentCase.SCARDeterminationAction) // Removed index
                         ? true 
                         : false,
            };
        });
    };

    const rows = useMemo(() =>{
        return(
        createRows(getNegotiationObj.cases)
        )
    }, [getNegotiationObj]);

    const tableProps = useMemo(() => ({ // custom table props for case review vverrsion of table.
        density: 'compact', // how closely spaced
        // disableDensitySelector: true, // remove this option from the table header
        // disableColumnResize: true, // can change this enable resizing.
        // checkboxSelection: false,
        // compTable: true, // in lower levvel MuiTable use this to set the backgrorund color.
        disableRowSelectionOnClick: true, // this will disable row selection on click
        // disableAutosize: true,
        // hideFooter: true, // remove the pagination / footer.
      }), []);

    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGridPro
                {...tableProps}
                rows={rows}
                columns={headers}
                pageSize={10}
                rowsPerPageOptions={[10, 25, 50, 100]}
                checkboxSelection
                onRowSelectionModelChange={handleSelectionChange}
                rowSelectionModel={selectionModel}
              //   onCellClick={(params) => {
              //     if (params.field === '__check__') { // Checks if the click was on the checkbox cell
              //       console.log('firing')
              //         handleCellClick(params);
              //     }
              // }}
            />
        </div>
    );
}