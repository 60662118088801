import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useCompStore, usePersistedDataStore } from '../store.js';
import DownloadButton from './DownloadButton.js';
import { showModalAndWaitForSelection } from '../../common/ShowModalAndWaitForSelection.js'; // you should be using this instead of ur manual jury rigged version in fetchcomps.
import axiosInstance from '../../axiosConfig.js';
import Button from '@mui/material/Button';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SummaryCaseReviewTable from './SummaryCaseReviewTable.js';

function ReviewSettlements({isAdmin=false, setReviewPage}) {
  const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj);
  const setNegotiationObj = usePersistedDataStore((state) => state.setNegotiationObj);
  const getCompSheet = usePersistedDataStore((state) => state.compSheet)
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const comp = parseInt(queryParams.get('comp')) - 1 || 0 ; // Subtract 1
  const taxYear = queryParams.get('TaxYear');
  const courtDate = queryParams.get('CourtDate') || 'All';
  const MuniCode = queryParams.get('MuniCode') || 'All';
  const [countState, setCountState] = useState(0);
  const [totalScarCases, setTotalScarCases] = useState(0)
  const [avgReduction, setAvgReduction] = useState(0);
  const [totalNC, setTotalNC] = useState(0);
  const [taxSavings, setTaxSavings] = useState(0)
    let runningSetlement = 0; // local var to tally your settlement value.
    let runningIFMV = 0; // var to tally your IFMV's (as cases are settled to calc the % reduct)
    let runningNC = 0;
  const fileName = `${MuniCode}_${taxYear}${courtDate === 'All' ? '' : `_${courtDate}`}`;
  const [selectionModel, setSelectionModel] = React.useState([]);


    // For the download
    const keys = ['PID', 'Address', 'PropertyTown', 'PropertyZip', 'Name', 'SCARIndexNumber','AssessmentTown', 'IFMVTown', 'RunDate', 'SalePrice', 'SaleDate', 'RecentSale', 'EstimatedTaxes', 'SubjectMarketValue', 'OfferValue', 'RAR', 'RequestedAssessment', 'PctReduction', 'CaseNotes', 'RepID', 'settled'];

    // LETS UPDATE this to be memoized not 
    useEffect(() => {
      updateNegotiation(); // Consolidated function call
    }, []);

    // this needs to be memoized (or broken apart) you get sales data every time I think?
    async function updateNegotiation() {
      let runningSettlement = 0;
      let runningIFMV = 0;
      let runningNC = 0;
  
      // Load sales data
      const uids = getNegotiationObj.cases.map((item) => item.PID);
      const salesResponse = await axiosInstance.post('/get_sales_data', {
        TaxYear: getNegotiationObj.TaxYear,
        MuniCode: MuniCode,
        uids: uids,
      });
  
      const salesData = salesResponse.data;
      const updatedCases = getNegotiationObj.cases.map((caseItem) => {
        const saleInfo = salesData.find((sale) => sale.pid === caseItem.PID);
        if (saleInfo) {
          return {
            ...caseItem,
            SaleDate: saleInfo.saleDate,
            SalePrice: saleInfo.salePrice,
            RecentSale: saleInfo.recentSale,
          };
        }
        return caseItem;
      });
  
      // Initialize negotiation object with updated cases
      const finalNegotiationObj = { ...getNegotiationObj, cases: updatedCases };
  
      // Calculate savings and scar cases
      const countSettled = updatedCases.reduce((count, obj) => {
        if (obj && obj.type !== null) {
          const compSheetItem = getCompSheet[updatedCases.indexOf(obj)];
          if (compSheetItem && compSheetItem.PropertyAssessment) {
            runningIFMV += parseInt(compSheetItem.PropertyAssessment[0]);
            runningSettlement += parseInt(obj.SCARDeterminationValue);
            if (obj.SCARDeterminationAction === 'W' || obj.SCARDeterminationAction === 'SD') {
              runningNC++;
            }
          }          
          if (obj.settled) {
            return count + 1;
          }
        }
        return count;
      }, 0);

      console.log(getCompSheet)
  
      setCountState(countSettled);
      setAvgReduction((runningSettlement - runningIFMV) / runningIFMV);
      setTaxSavings(runningIFMV * 0.02 * ((runningSettlement - runningIFMV) / runningIFMV));
      setTotalNC(runningNC);
      setTotalScarCases(updatedCases.filter((item) => item.type !== null)); // Updated totalScarCases based on new cases
  
      // Update the cases within the final negotiation object
      console.log(finalNegotiationObj)
      finalNegotiationObj.cases = finalNegotiationObj.cases.map((item, index) => {
        if (item && item.type !== null) {
          const compSheetItem = getCompSheet[index];
          console.log('compSheetItem')

          if (compSheetItem && compSheetItem.PropertyAssessment) {
            // 
            let requestedAssessment = item.SubjectMarketValue * compSheetItem.RAR[0];
            if(item.OfferValue){
              const offeredAssessment = item.OfferValue * compSheetItem.RAR[0];
              requestedAssessment = offeredAssessment;
            }
  
            if (compSheetItem.IFMV[0] > 450000 && requestedAssessment < (0.75 * compSheetItem.PropertyAssessment[0])) {
              item.SubjectMarketValue = 0.75 * compSheetItem.IFMV[0];
              requestedAssessment = 0.75 * compSheetItem.PropertyAssessment[0];
            }
            // calc % by comparing Asses
            let percentReduction = ((compSheetItem.PropertyAssessment[0]-requestedAssessment)/compSheetItem.PropertyAssessment[0]);
            // Override your pct reduction if you have an offer value.

  
            return {
              ...item,
              Address: compSheetItem.Address[0],
              PropertyAssessment: compSheetItem.PropertyAssessment[0],
              RAR: compSheetItem.RAR[0],
              RequestedAssessment: parseInt(requestedAssessment),
              PctReduction: percentReduction,
              PropertyTown: compSheetItem.Town[0],
              PropertyZip: compSheetItem.ZipCode[0]
              // add in propertyTown getCompSheet.Town[0]
              // add in rundate (u have this already)
              // add in property zip getCompSheet.ZipCode[0]
            };
          }
        }
        return item;
      });
  
      // Set the updated negotiation object only once
      setNegotiationObj(finalNegotiationObj);

      // Handle missing SubjectMarketValue
      // if (missingValuesFlag) {
      //   const userSelection = await showModalAndWaitForSelection(
      //     "You are missing subject market values",
      //     'Generate them in the admin portal, then re-run and proceed to download xls.',
      //     'ignore',
      //   );
      //   if (userSelection === 'ignore') {
      //     console.log('User chose to ignore missing values.');
      //   }
      // }
  
      // became redundant now.
      // Split SCAR cases
      // const filteredScarCases = finalNegotiationObj.cases.filter((caseItem) => caseItem.SCARFiled === 1);
      // setScarCases(filteredScarCases);
    }

    const handleCellClick = useCallback((selection) => {
      console.log("Row ID:", selection);
      setSelectionModel(selection);
    }, [selectionModel]);

  return (
    // On this page, if there's no settlements (or even if there are) - have an option to "save review" - this will save the comp order,
    // And save any min/max offers (and settlements)
    <div className=''>
      <div className='h-32 bg-white mt-4 flex flex-col justify-center'> 
        <div className='text-2xl font-bold text-center'>Settlement Review</div>
        <div className='text-center'>Placeholder for district summary stats.</div>
      </div>
      <div className='flex justify-between mt-2 mb-0.5'>
      {/* Button to download JSON data based on selection */}
        <Button onClick={()=>setReviewPage(false)}
          startIcon={<KeyboardBackspaceIcon/>}
            >Back to Comps
        </Button>
      <div className=''>
        <DownloadButton
          caseData={getNegotiationObj.cases}
          fileName={fileName}
          selectedRows={selectionModel}
          keys={keys}
        />
      </div>
    </div>
    
    {/* Old table, this can be done away with basically. use the math to provide some summary stats at top. */}
    <SummaryCaseReviewTable handleCellClick={handleCellClick} selectionModel={selectionModel}/>
    
    </div>
  );
} export default ReviewSettlements
