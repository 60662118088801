
import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const AdjustmentsPopover = ({adjustments}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };

    const handleClose = () => {
        setAnchorEl(null);
      };
    
      const open = Boolean(anchorEl);
      const id = open ? 'simple-popover' : undefined;
  
    return (
      <div className="flex items-center text-xs">
        {/* Display adjustments */}
        <span
          className=" mr-2"
          aria-haspopup="true"
        >
          {typeof adjustments.Sqft === 'number' ? `$${adjustments.Sqft.toLocaleString()}/Sqft` : adjustments.Sqft}
        </span>
        <span
          className=""
          aria-haspopup="true"
        >
          {typeof adjustments.Acres === 'number' ? `$${adjustments.Acres.toLocaleString()}/Acre` : adjustments.Acres}
        </span>

        <InfoOutlinedIcon onClick={handleClick} className="cursor-pointer ml-1" fontSize='inherit'/>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Typography sx={{ p: 1 }}>
            <span className="flex flex-col space-y-2">
              {Object.entries(adjustments).map(([key, value]) => (
                <span key={key} className="flex justify-between">
                  <span className="font-medium">{key}:</span>
                  <span>
                    {typeof value === 'number' ? `$${value.toLocaleString()}` : value}
                  </span>
                </span>
              ))}
            </span>
          </Typography>
        </Popover>
      </div>
    );
  } 

    export default AdjustmentsPopover;