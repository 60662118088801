import React from 'react';
import { FormControl } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateField } from '@mui/x-date-pickers/DateField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const DatePicker = ({ value, onChange, type, label }) => {
  return (
    <FormControl variant="standard" sx={{ mx: 1, mt: '10px', minWidth: 110, maxWidth: 110 }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateField
          sx={{
            '& .MuiInputBase-root': { cursor: 'text', paddingTop: '0px', paddingBottom: '0px', paddingRight: '0px' },
            '& .MuiInputBase-input': { paddingTop: '8px', paddingBottom: '8px', paddingLeft: '8px' },
            '&.Mui-selected': { backgroundColor: 'transparent !important' },
            '&::placeholder': { fontSize: '0.25rem' },
            '& .MuiInputLabel-root': { backgroundColor: 'transparent' },
          }}
          value={value}
          label={label}
          InputLabelProps={{ shrink: true }}
          onChange={onChange}
          format="YYYY-MM-DD"
        />
      </LocalizationProvider>
    </FormControl>
  );
};

const DateRangePicker = ({ fromValue, toValue, onFromChange, onToChange }) => {
  return (
    <div className='flex items-center'>
      <DatePicker 
        value={fromValue} 
        onChange={onFromChange} 
        type="from" 
        label="From:" 
      />
      <DatePicker 
        value={toValue} 
        onChange={onToChange} 
        type="to" 
        label="To:" 
      />
    </div>
  );
};

const SingleDatePicker = ({ value, onDateChange }) => {
  if (value ==='') {
    value = null;
  }
  return (
    <DatePicker 
      value={value} 
      onChange={onDateChange} 
      label="Date:" 
    />
  );
};

export { DateRangePicker, SingleDatePicker };
