import React, { useState, useCallback } from 'react';
import DarkModeSwitcher from './DarkModeSwitcher';
import {useLocalDataStore, useAdminSettingsStore, usePersistedDataStore} from './store';
import {useAuth } from "../firebase";
import DropdownUser from './DropdownUser';
import propriety from '../images/propriety-32.png'
import { NavLink, useNavigate, useLocation, Link } from 'react-router-dom';
import { current } from 'immer';
import _ from 'lodash';

const Header = ({setLoading, handleChange, demoPage}) => {
  const getselectedFilter = useAdminSettingsStore((state) => state.selectedFilter)
  const setCompSheet = usePersistedDataStore((state) => state.setCompSheet) // set comps
  const getCompSheet = usePersistedDataStore((state) => state.compSheet)
  const setCaseNumber = usePersistedDataStore((state) => state.setCaseNumber)
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const setMap = useAdminSettingsStore((state) => state.setMap)
  const getMap = useAdminSettingsStore((state) => state.map)
  const [isDropdownOpen, setDropdownOpen] = useState(false); // filter dropdown toggle
  const setSelectedFilter = useAdminSettingsStore((state) => state.setSelectedFilter)
  const getSidebarOpen = useLocalDataStore((state)=> state.sidebarOpen)
  const setSidebarOpen = useLocalDataStore((state)=> state.setSidebarOpen)
  const location = useLocation()
  
  // extract MuniCode and Taxyear from the searchParams
  const searchParams = new URLSearchParams(location.search);
  const MuniCode = searchParams.get('MuniCode');
  const TaxYear = searchParams.get('TaxYear');
  const navigate = useNavigate();
  const { pathname } = location;
  const currentUser = useAuth();
  const [checkedIndices, setCheckedIndices] = useState([]);
  const userLogged = localStorage.getItem('userInfo') ? true: false
    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };
  
    function cleanSearch(){
        setSearchTerm('') // when you click in, clear out the search.
        setCheckedIndices([])
        setSuggestions([])
    }

  function searchPropertyAddress(obj, searchTerm) { // optimized search function to not iterrate ovver already invavlidated suggestion.
    const suggestions = [];

    console.log(checkedIndices)
    for (let i = 0; i < obj.length; i++) {
      if (checkedIndices.includes(i)) {
        continue; // Skip this index if it has already been checked
      }
      // console.log(`running for ${i}`)
      const current = obj[i];
      const currentSubjAddress = current.Address[0];

      if (current && currentSubjAddress) {
        if (currentSubjAddress.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
          suggestions.push({ address: currentSubjAddress, index: i });
        } else {
          setCheckedIndices(prevIndices => [...prevIndices, i]); // Add this index to the checked list
        }
      }
    }
    return suggestions;
  }

function debounce(fn, delay) {
    let timeoutId;
    return function(...args) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            fn(...args);
        }, delay);
    };
}

function debouncedHandleInput(event) {
  console.log(event.keyCode)
  const value = event.target.value;
  setSearchTerm(value);

  if (value) {
    const filteredSuggestions = searchPropertyAddress(getCompSheet, value);
    setSuggestions(filteredSuggestions);
  } else {
    setSuggestions([]);
  }

  // If the input value is empty and the user pressed backspace, reset the checkedIndices array
  if (event.key === 'Backspace') {
    console.log('reset')
    setCheckedIndices([]);
  }
}

// const debouncedHandleInput = useCallback(debounce((event) => {
//   handleInput(event);
// }, 300), [getCompSheet]);

    const handleFilterClick = (filter) => {
      console.log('fired')
      setSelectedFilter(filter);
      setDropdownOpen(false);
      if(filter!==getselectedFilter){
      setLoading(true)
      if(getCompSheet){ // here we re-fetch and reset our sheet.
          fetch(`/KNNModel`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(getCompSheet[0].parcel_id) // Include the PID into this object
          })
              .then(r=>r.json())
              .then(games=> {
                games = games.message
                games = JSON.parse(games)
              //   setBrennanIsABitch(games[1])
                console.log(games)
              setCompSheet(games[0]) // set your local compsheet to render
              // setLoading(false);
              setLoading(false)
              // Add a condition for IF THE FILTER IS NOT THE SAME ONE
              if(filter!==getselectedFilter&&filter==='Only Sales'||filter==='Sales (IFMV)'){ // this should re-run the model anyways, THEN filter
                const today = new Date(); // Get today's date
                const threeYearsAgo = new Date(); // Create a new Date object 3 years ago
                threeYearsAgo.setFullYear(today.getFullYear() - 3);
                const filteredSheet = getCompSheet.filter((property, index) => {
                  if (index === 0) {
                    return true; // Always include the first property
                  }
                  const soldDate = new Date(property.MLS_title_date); // Convert MLS_title_date to a Date object
                  const soldPrice = property.sold_price;
                  return soldPrice !== 0 && soldDate.getTime() >= threeYearsAgo.getTime();
                });
                setCompSheet(filteredSheet);
              }})
          }
      }else{setLoading(false)}
  };


function searchProp(property){ // your search function (re-running full model atm.)
  // This "e" returns the index of the property we are looking for in our DB. This should probably be its own DB call to return
  // The compsheet for that property instead of trying to re-run the whole model again.
  console.log(property)
  const url = new URL(location.pathname+location.search, window.location.origin);
  const searchParams = new URLSearchParams(url.search);
  searchParams.set('comp', property.index+1);  
  // setCaseNumber(property.index)
    setSuggestions([])
    setSearchTerm(property.address)
    const newUrlString = `${url.pathname}?${searchParams.toString()}`;
    navigate(newUrlString);
}

function handleBackspace(event) {
  if (event.key === 'Backspace') { // Check if the keyCode is 8 (backspace) and input value is empty
    console.log('reset');
    setCheckedIndices([]);
  }
}

  return (
    <header className="sticky top-0 z-[999] flex w-full bg-white shadow-sm dark:bg-boxdark dark:drop-shadow-none">
      <div className="flex flex-grow items-center justify-between py-1 px-4 shadow-2 md:px-6 2xl:px-11">
        
        <div className='flex items-center'>
        {!getSidebarOpen?
        <div className="flex items-center gap-2 sm:gap-4">
        {/* Hamburger Toggle BTN */}
        <div className="flex relative inline-block group items-center">
  <button
    onClick={() => {
      if (!demoPage) setSidebarOpen(!getSidebarOpen);
    }}
    className="text-2xl cursor-pointer flex items-center justify-center mb-1 text-gray-500 font-light" // Added flex and justify-center
    aria-label="Toggle Sidebar"
  >
    &#9776; {/* This is the Unicode character for the hamburger icon */}
  </button>

  {/* Tooltip */}
  {demoPage ? (
    <div className="bg-gray-800 text-white p-2 absolute top-full transform w-[175px] hidden group-hover:block">
      Hold your horses! Public access soon.
    </div>
  ) : null}
  
  {/* New breadcrumb for where you ran */}
</div>
  
        {/* This is the logo for small screen. */}
        <Link className="block flex-shrink-0 lg:hidden" to="/">
          {/* PLACEHOLDER - include your  */}
          <img src={propriety}></img>
          {/* You can put in your logo here if u want, otherwise delete. */}
          {/* <img src={Logo} alt="Logo" /> */}
        </Link>
      </div>
        :
        null  
            }

             {/* {MuniCode !== null && TaxYear !== null?
          <div className='ml-2 text-blue-500 font-semibold'> {MuniCode} ({TaxYear}) </div>
          :
          null} */}
          </div>
    
    {/* SEARCH CONTAINER  */}
     {getCompSheet.length>0
     ?
        // {/* Header (inc. search when sidebar closed.) */}
        <div className="flex items-center gap-3 2xsm:gap-7">
        <ul className="flex items-center gap-2 2xsm:gap-4">
        <ul className="flex items-center">
          
        <div className="sm:block">
          {/* {currentUser? */}
            <div className="relative group">
              <button className="absolute top-1/2 left-0 -translate-y-1/2">
                {/* Add an onclick to this button to do the search */}
                <svg
                  className="fill-body hover:fill-primary dark:fill-bodydark dark:hover:fill-primary"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.16666 3.33332C5.945 3.33332 3.33332 5.945 3.33332 9.16666C3.33332 12.3883 5.945 15 9.16666 15C12.3883 15 15 12.3883 15 9.16666C15 5.945 12.3883 3.33332 9.16666 3.33332ZM1.66666 9.16666C1.66666 5.02452 5.02452 1.66666 9.16666 1.66666C13.3088 1.66666 16.6667 5.02452 16.6667 9.16666C16.6667 13.3088 13.3088 16.6667 9.16666 16.6667C5.02452 16.6667 1.66666 13.3088 1.66666 9.16666Z"
                    fill=""
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.2857 13.2857C13.6112 12.9603 14.1388 12.9603 14.4642 13.2857L18.0892 16.9107C18.4147 17.2362 18.4147 17.7638 18.0892 18.0892C17.7638 18.4147 17.2362 18.4147 16.9107 18.0892L13.2857 14.4642C12.9603 14.1388 12.9603 13.6112 13.2857 13.2857Z"
                    fill=""
                  />
                </svg>
              </button>

{/* Updated your search to look better. */}
              <fieldset className="w-full space-y-1 dark:text-gray-100">
                <label htmlFor="Search" className="hidden">Search</label>
                <div className="relative">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                    <button type="button" title="search" className="p-1 focus:outline-none focus:ring">
                      <svg fill="currentColor" viewBox="0 0 512 512" className="w-4 h-4 dark:text-gray-100">
                        <path d="M479.6,399.716l-81.084-81.084-62.368-25.767A175.014,175.014,0,0,0,368,192c0-97.047-78.953-176-176-176S16,94.953,16,192,94.953,368,192,368a175.034,175.034,0,0,0,101.619-32.377l25.7,62.2L400.4,478.911a56,56,0,1,0,79.2-79.195ZM48,192c0-79.4,64.6-144,144-144s144,64.6,144,144S271.4,336,192,336,48,271.4,48,192ZM456.971,456.284a24.028,24.028,0,0,1-33.942,0l-76.572-76.572-23.894-57.835L380.4,345.771l76.573,76.572A24.028,24.028,0,0,1,456.971,456.284Z"></path>
                      </svg>
                    </button>
                  </span>
                  <input 
                  type="search" 
                  name="Search" 
                  autoComplete="off"
                  placeholder="Search..." 
                  className="w-32 py-2 pl-10 text-sm rounded-md sm:w-auto focus:outline-none dark:bg-gray-800 dark:text-gray-100 focus:dark:bg-gray-900 focus:dark:border-violet-400" 
                  disabled={demoPage}
                  onClick={()=>cleanSearch()}
                  value={searchTerm}
                  onChange={debouncedHandleInput}
                  onKeyDown={handleBackspace} // Attach the event listener to detect backspace key press
                  />
                </div>
              </fieldset>

              
              {suggestions.length > 0 && (
                <ul className="absolute mt-1 bg-white border shadow-md w-full p-1 rounded-md">
                    {suggestions.map((property) =>
                            <li onClick={() => searchProp(property)} key={property.address} disabled={ demoPage} className="px-4 py-2 cursor-pointer hover:bg-[#F1F5F9] hover:rounded-md">
                                {property.address}
                                {/* add an onclick to search for THAT property's index to */}
                            </li>
                    )}
                </ul>
            )}

            </div>
            
        </div>

          {pathname.includes('table')? // bunch of fshit you don't reeally use anymore
          // /* Only display  this if at /table route */
          // /* Other header items: filter and icons below. */
          <>
                {/* <li className="mx-2 dark:text-white">
                      <button
                className="flex items-center focus:outline-none"
                onClick={toggleDropdown}
              >
                <span className="ml-4 mr-2">Filter:</span>
                <span className="font-medium">{getselectedFilter}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`h-4 w-4 ${
                    isDropdownOpen ? "transform rotate-180" : ""
                  }`}
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M6.293 6.707a1 1 0 0 0 0 1.414L10 11.414l3.707-3.707a1 1 0 0 0 0-1.414A1 1 0 0 0 12.414 6L10 8.414l-2.707-2.707a1 1 0 0 0-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              <ul
              className={`absolute ${getCompSheet?' w-32':' w-32'} ml-8 bg-white shadow-lg text-center rounded-md overflow-hidden z-10 dark:bg-boxdark ${
                isDropdownOpen ? "" : "hidden"
              }`}>
                  <li
                  className="px-4 py-2 hover:bg-meta-2 dark:hover:bg-[#1B222C] "
                  onClick={() => handleFilterClick("All Comps")}>
                      <a href="#" className="block">All Comps</a>
                  </li>
                  <li
                  className="px-4 py-2 hover:bg-meta-2 dark:hover:bg-[#1B222C]"
                  onClick={() => handleFilterClick("Only Sales")}>
                      <a href="#" className="block">Only Sales</a>
                  </li>
                  <li
                  className="px-4 py-2 hover:bg-meta-2 dark:hover:bg-[#1B222C]"
                  onClick={() => handleFilterClick("Sales (IFMV)")}>
                      <a href="#" className="block">Sales (IFMV)</a>
                  </li>
              </ul>
          </li> */}

                      {/* /* Map icon toggle */}
                      <li className='mx-2'>
                        <button
                        // Need a Map toggle state.
                          onClick={() => setMap(!getMap)}
                          className={getMap ? 'bg-meta-2 dark:bg-meta-4 rounded-full' : null}
                          style={{ width: '40px', height: '40px', padding: '3px' }} // Adjust the width and height as needed
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" className="w-8 h-8 dark:stroke-white" style={{ width: '100%', height: '100%' }}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z" />
                          </svg>
                        </button>
                      </li>
                      {/* /* Map icon toggle */}
                      <li>
                        {/* Took out the table view toggle. */}
                        
                        {/* <button
                          onClick={() => setSalesCompReport(!getSalesCompReport)}
                          className={getSalesCompReport ? 'bg-meta-2 dark:bg-meta-4 rounded-full' : null}
                          style={{ width: '40px', height: '40px', padding: '3px' }} // Adjust the width and height as needed
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1}
                            stroke="currentColor"
                            className="w-8 h-8 dark:stroke-white"
                            style={{ width: '100%', height: '100%' }} // Make sure the SVG fills the button
                          >
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" />

                          </svg>
                        </button> */}
                      </li>
                      {/* /* Compsheet (table) view toggle w/ active state */ }
          </>
        :
        null}
      </ul>

            {/* <!-- Dark Mode Toggler --> */}
            <DarkModeSwitcher />
            {/* <!-- Dark Mode Toggler --> */}
      </ul>
      {userLogged?
          <DropdownUser/>
          :
          <NavLink className='flex bg-primary items-center justify-center text-center text-white py-1 px-2 font-semibold rounded-md dark:bg-violet-400 dark:text-gray-900' to='/signin'>Login</NavLink>
          }

        </div>
      :
        null
      }     
      </div>
    </header>
  );
};

export default React.memo(Header);