import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

function NewDropdown({
    value,
    label,
    width = 135,
    dropdownOptions,
    updateFunction,
    updateFunctionArgs,
    disabled
}) {
    return (
        <div>
            <FormControl id="override" className={`flex w-fit min-w-[${width}px] justify-end`} size='small' fullWidth>
                <InputLabel 
                // Set inputlabel background to transparent
                style={{ backgroundColor: 'transparent' }}
                >{label}:</InputLabel>
                <Select
                    value={value}
                    className={`w-fit min-w-[${width}px]`}
                    label={label}
                    disabled={disabled}
                    // I fkn hate this stupid ternary.
                >
                    {dropdownOptions.length < 1 ? (
                        <div className='flex m-auto items-center justify-center'>
                            <div className="flex m-auto justify-center items-center h-6 w-6 animate-spin rounded-full border-4 border-solid border-primary border-t-transparent"/>
                        </div>
                    ) : (
                        dropdownOptions.map((option, index) => (
                            <MenuItem
                                key={index}
                                value={option.value}
                                onClick={() => updateFunction({ ...updateFunctionArgs, updateValue: option.value })}
                                style={{ minHeight: '30px' }}
                            >
                                {option.label}
                            </MenuItem>
                        ))
                    )}
                </Select>
            </FormControl>
        </div>
    );
}

export default React.memo(NewDropdown);