import React, { useEffect, useState, useRef, useCallback } from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import NewDropdown from '../NewDropdown'
import { useLocalDataStore, usePersistedDataStore, } from '../store';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import { useNavigate, useSearchParams, useLocation, NavLink } from 'react-router-dom';
import { updateMuniObj, getMunicipalityOptions, getVillageOptions, getCountyOptions } from '../../common/helper_functions';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

function GraphParams({nyDataObj, taxYear, handleParamsChange, baseYear, timeAdjOverride, microLoad, setMicroLoad, loadGraph}){
  // This entire updatefunction logic, and the low level usage of nydataobj is so stupid. This should all be abstracted when we have time

  const yearDropdownOptions = [ // hardcoding for rn w/ carmel. in the future this should be dynamic, and drivven by what data is available.
        {value: 2015, label: 2015},
        {value: 2016, label: 2016},
        {value: 2017, label: 2017},
        {value: 2018, label: 2018},
        {value: 2019, label: 2019},
        {value: 2020, label: 2020},
        {value: 2021, label: 2021},
        {value: 2022, label: 2022},
        {value: 2023, label: 2023},
        {value: 2024, label: 2024},
        {value: 2025, label: 2025},
  ]
    const chartOptions = [
      { label: "Distribution", icon: <CandlestickChartIcon />, value: "Distribution" },
      { label: "X,Y Scatter", icon: <ScatterPlotIcon />, value: "X,Y Scatter" },
      { label: "3-D Scatter", icon: <AutoGraphIcon />, value: "3-D Scatter" },
    ]

    const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj)
    const [villageOptions, setVillageOptions] = useState([{value:'All', label:'All'}])
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const urlString = location.pathname+location.search
    // const MuniCode = queryParams.get('MuniCode') || 'All';
    const [error, setError] = useState(false)
    const municipality = queryParams.get('municipality') || 'All';
    const county = queryParams.get('county') || 'All';
    const village = queryParams.get('village') || 'All';
    const courtDate = queryParams.get('CourtDate') || '';
    const [muniOptions, setMuniOptions] = useState([{value:'All', label:'All'}]) // allow "all to be a default state."
    const [countyOptions, setCountyOptions] = useState([{ value: "All", label: "All", selectVal: 'All'},
        ...Object.keys(nyDataObj).map(key => ({
            label: key,
            value: nyDataObj[key].selectVal
        }))])
  
      const [courtMunis, setCourtMunis] = useState([]); // this should really be memoized -- and in your other list, instead of the complex handle
      // function to get / set the muni, you should just pass the options down so ti knows its a nested list, and the thing returned to map over, is the county key.
  
      useEffect(() => {
        // Update the available municipalities when the county is updated.
        if (county !== '' && county !== 'All' && county !== 'select') {
          getMunicipalityOptions({ // this is the dumb function I was talking about above.
            setMuniOptions,
            nyDataObj,
            county,
            courtMuni: [],
            taxYear,
            navigate,
            countyOptions,
          });
        }
        console.log(county);
      }, [county]);

      // REFACTOR This to lkist out params as an array and map over them to update the options.
      useEffect(()=>{ // update the avvailable village options when municipality is updated.
        console.log('running the get village useeffect (do this in the function for muniupdate')
        getVillageOptions(setVillageOptions, nyDataObj, municipality, county)
      },[municipality])
    
      // Memoized function to update municipality options
      const updateMuni = useCallback(({ updateField, updateValue, updateString = '', urlString }) => {
        updateMuniObj({
          updateField,
          updateValue,
          updateString,
          navigate,
          urlString,
          nyDataObj,
          county,
          taxYear,
          setCountyOptions,
          courtMuni: courtMunis,  // Pass courtMunis from state
        });
      }, [navigate, nyDataObj, county, taxYear]);
  
    // Combine the useeffect function of updatecourtmunioptions into a usememo that sets the munioptions.


    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const StyledMenu = styled((props) => ( // for button dropdown.
        <Menu
          elevation={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          {...props}
        />
      ))(({ theme }) => ({
        '& .MuiPaper-root': {
          borderRadius: 6,
          marginTop: theme.spacing(1),
          minWidth: 180,
          color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
          boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
          '& .MuiMenu-list': {
            padding: '4px 0',
          },
          '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
              fontSize: 18,
              color: theme.palette.text.secondary,
              marginRight: theme.spacing(1.5),
            },
            '&:active': {
              backgroundColor: alpha(
                theme.palette.primary.main,
                theme.palette.action.selectedOpacity,
              ),
            },
          },
        },
      }));


      // This whole component should be refactored to map over the input list of the things (county, muni, village, taxyear, baseyear, timeadj) and then update the options for each of them.
      // This would be a lot cleaner, and would allow for the options to be updated in a single function, instead of having to update each one individually.
    return(
        <div className={`mx-auto grid content-center gap-1.5 items-center justify-center text-center py-1.5`}>
            
                {/* Top Grid row: County, Muni, Taxyear, and Lag dropdown */}
              <div className='flex m-auto justify-center items-end min-w-[190px]'>
            <div className='flex m-auto gap-4 justify-center items-end min-w-[190px]'>
              {/* County dropdown, always visible. */}
                <div className='countyDropdown'>

                <NewDropdown
                      value={county}
                      label="County"
                      dropdownOptions={countyOptions.map(option => ({
                          value: option.value,
                          label: option.label
                      }))}
                      updateFunction={updateMuni}
                      updateFunctionArgs={{
                          updateField: 'county',
                          updateString: '', // assuming `option.label` is not needed
                          navigate,
                          urlString,
                          nyDataObj,
                          county,
                          TaxYear: taxYear,
                          setCountyOptions,
                      }}
                      disabled={countyOptions.length > 1 ? false : true}
                  />
                  {courtDate !== '' && county ==='select' ? <span className='absolute bottom-[-10] w-[150px] text-xs text-danger'>Select a County</span>
                  : null}
                </div>

            {/* Municipality dropdown */}
            <div className='relative flex-col items-center'>
                <NewDropdown
                    value={municipality}
                    label="Municipality"
                    dropdownOptions={muniOptions.map(option => ({
                        value: option.value,
                        label: option.selectVal,
                        county: option.county,   // Passing additional fields for updateFunction
                        TaxYear: option.TaxYear  // Passing additional fields for updateFunction
                    }))}
                    updateFunction={(...args) => {
                    // console.log('Arguments passed to updateMuni:', args);
                    updateMuni(...args);
                }}
                    updateFunctionArgs={{
                        updateField: 'municipality',
                        updateString: '', // assuming `option.label` is not needed
                        urlString,
                        nyDataObj,
                        county,
                        navigate,
                        setCountyOptions,
                    }}
                    disabled={county === 'All' && courtDate=== '' || county==='select'}
                />
                {error ? <span className='absolute bottom-[-10] w-[150px] text-xs text-danger'>{error}</span>
                : courtDate !== '' && municipality === 'select' && county !=='select' ? <span className='absolute bottom-[-10] w-[150px] text-xs text-danger'>Select a Municipality</span>
                    : null}
            </div>


              {/* Village */}
            {villageOptions.length > 1 ?
            <NewDropdown
                value={village}
                label="Village"
                dropdownOptions={villageOptions.map(option => ({
                    value: option.value,
                    label: option.selectVal
                }))}
                updateFunction={updateMuni}
                updateFunctionArgs={{
                    updateField: 'village',
                    updateString: '', // assuming `option.label` is not needed
                    urlString,
                    nyDataObj,
                    navigate,
                    county: null,  // You can pass actual county if available
                    TaxYear: null,  // You can pass actual TaxYear if available,
                    setCountyOptions,
                }}
                disabled={(municipality === 'All' && villageOptions[0] === 'All') || villageOptions.length > 1 ? false : true}
            />
            :null  
            }

              {/* Taxyear drop */}
                          <div className=' flex justify-center m-auto content-center'>
                            <FormControl className='flex w-fit justify-end' size='small' fullWidth>
                                <InputLabel id="override"
                                sx={{backgroundColor: 'transparent',}}
                                >Tax Year</InputLabel>
                                  <Select
                                    value={taxYear}
                                    className='w-fit'
                                    sx={{minWidth: '105px'}}
                                    label='Tax Year'
                                    labelId="filter-type-label"
                                    id="override"
                                  >
                                    {yearDropdownOptions.map((year, index) => (
                                      <MenuItem
                                        key={index}
                                        value={year.value} // Convert boolean to string to match the state type
                                        onClick={()=>handleParamsChange(year.value, 'Tax Year')}
                                        style={{ minHeight: '30px' }} // Set your desired minimum height here
                                      >
                                        {year.value}
                                      </MenuItem>
                                    ))}
                                  </Select>
                            </FormControl>
                        </div>


                        {/* Base year */}
                        <div className=' flex justify-center m-auto content-center'>
                            <ParamInput
                            value={baseYear}
                            label="Base Year"
                            updateFunction={handleParamsChange}
                            updateFunctionArgs={'Base Year'}
                            disabled={false}
                            dropdownOptions={yearDropdownOptions}
                            />
                        </div>

                      {/* time adj overrride */}
                      <div className="flex items-center space-x-2">
                        <FormControl className="flex w-fit justify-end" size="small">
                          <TextField
                            label="Time Adj" // This will create the label
                            placeholder="Time Adj"
                            value={timeAdjOverride}
                            onChange={(e) => handleParamsChange(e.target.value,'Time Adjustment')}
                            variant="outlined"
                            size="small"
                            sx={{zIndex: 0 }}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">%</InputAdornment>, // Adds % inside the input
                              inputProps: {
                                style: { width: '60px' }, // Fixed width for input
                                type: 'number', // Ensure input is a number
                                min: 0,
                                max: 100,
                                step: 0.01, // Precision for percentage
                              },
                            }}
                            InputLabelProps={{
                              style: { backgroundColor: 'transparent' } // Transparent label background
                            }}
                          />
                        </FormControl>
                      </div>
                {/* Graph Generation button */}
                        {microLoad?
                        <LoadingButton
                        id="demo-customized-button"
                        loading
                        loadingPosition='end'
                        className='max-w-[110px]'
                        aria-controls={open ? 'demo-customized-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        variant="contained"
                        disableElevation
                        onClick={handleClick}
                        endIcon={<KeyboardArrowDownIcon />}
                        >
                        Graph
                    </LoadingButton>
                        :
                        <>
                        <Button
                        id="demo-customized-button"
                        className='max-w-[110px]'
                        variant="contained"
                        disableElevation
                        onClick={handleClick}
                        endIcon={<KeyboardArrowDownIcon />}
                        >
                        Graph
                        </Button>

                        <StyledMenu
                          id="demo-customized-menu"
                          MenuListProps={{
                            "aria-labelledby": "demo-customized-button",
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                        >
                          {chartOptions.map(({ label, icon, value }) => (
                            <MenuItem
                              key={value}
                              onClick={async () => {
                                setMicroLoad(true); // Set microLoad to true before loading the graph.
                                await loadGraph(value); // Wait for loadGraph to complete.
                                setMicroLoad(false); // Set microLoad to false after it completes.
                                handleClose(); // Close the menu after loading the graph.
                              }}
                              disableRipple
                            >
                              {icon}
                              {label}
                            </MenuItem>
                          ))}
                        <MenuItem onClick={handleClose} disableRipple>
                        <MoreHorizIcon />
                        More
                        </MenuItem>
                    </StyledMenu>
                    </>
                        }
                </div>
                </div>
                </div>
    )
}export default GraphParams

const ParamInput = ({value, label, updateFunction, updateFunctionArgs, dropdownOptions, disabled}) => {
  return (
    <div className=' flex justify-center m-auto content-center'>
    <FormControl className='flex w-fit justify-end' size='small' fullWidth>
        <InputLabel id="override"
        sx={{backgroundColor: 'transparent',}}
        >{label}</InputLabel>
          <Tooltip title="This determines which year's assessment will be used." sx={{zIndex: 0}}
                            PopperProps={{
                            style: { zIndex: 55 },
                            modifiers: [
                              {name: 'offset',
                              options: {
                                offset: [0, -10], // Adjusts the tooltip closer to the select
                              },
                              },
                            ],
                            }}>
                        <Select
                          value={value}
                          className='w-fit'
                          sx={{minWidth: '105px'}}
                          label={label}
                          labelId="filter-type-label"
                          id="override"
                        >
                          {dropdownOptions.map((year, index) => (
                            <MenuItem
                              key={index}
                              value={year.value} // Convert boolean to string to match the state type
                              onClick={()=>updateFunction(year.value, label)}
                              style={{ minHeight: '30px' }} // Set your desired minimum height here
                            >
                              {year.value}
                            </MenuItem>
                          ))}
                        </Select>
                        </Tooltip>
    </FormControl>
</div>
  )
}

