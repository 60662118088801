import React from 'react';
import { Route, Navigate } from 'react-router-dom';

const PrivateRoute = ({ element, fallbackPath = '/signin' }) => {
  // Replace this with actual session-based authentication logic
  const isLoggedIn = () => {
    // const userInfo = sessionStorage.getItem('userInfo'); // using sessionStorage now
    const userInfo = localStorage.getItem('userInfo'); // using sessionStorage now
    return userInfo !== null; // Checks if userInfo is stored in the session
  };

  return isLoggedIn() ? (
    element
  ) : (
    <Navigate to={fallbackPath} />
  );
};

export default PrivateRoute;