import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const FilterTypeSelector = ({ typeOptions, value, onChange }) => (
  <FormControl variant="standard" sx={{ mx: 1, minWidth: 75, maxWidth: 75 }}>
    <InputLabel
      id="filter-type-label"
      sx={{
        backgroundColor: 'transparent',
        '&.MuiInputLabel-shrink': { backgroundColor: 'transparent' },
      }}
    >
      Type
    </InputLabel>
    <Select
      labelId="filter-type-label"
      id="filter-type-select"
      value={value}
      onChange={onChange}
      label="Filter Type"
      sx={{
        zIndex: 100,
        backgroundColor: 'transparent',
        '& .MuiSelect-select': {
          backgroundColor: 'transparent !important',
        },
        '& .MuiPaper-root': {
          backgroundColor: 'transparent',
        },
        '& .MuiMenuItem-root': {
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
          },
          '&.Mui-selected': {
            backgroundColor: 'transparent !important',
          },
        },
      }}
    >
      {typeOptions.map(option => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default FilterTypeSelector;
