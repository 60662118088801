import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, Tooltip } from '@mui/material';

const FilterColumnSelector = ({ filterOptions, value, onChange, tooltipText }) => (
  <FormControl variant="standard" sx={{ mx: 1, minWidth: 100, maxWidth: 100 }}>
    <InputLabel
      id="filter-column-label"
      sx={{
        backgroundColor: 'transparent',
        '&.MuiInputLabel-shrink': { backgroundColor: 'transparent' },
      }}
    >
      Column
    </InputLabel>
    <Tooltip title={tooltipText} sx={{ zIndex: 0 }} disableInteractive
      PopperProps={{
        style: { zIndex: 55 },
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -10],
            },
          },
        ],
      }}
    >
      <Select
        labelId="filter-column-label"
        id="filter-column-select"
        value={value}
        onChange={onChange}
        label="Column"
        sx={{
          zIndex: 100,
          backgroundColor: 'transparent',
          '& .MuiSelect-select': {
            backgroundColor: 'transparent !important',
          },
          '& .MuiPaper-root': {
            backgroundColor: 'transparent',
          },
          '& .MuiMenuItem-root': {
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
            },
            '&.Mui-selected': {
              backgroundColor: 'transparent !important',
            },
          },
        }}
      >
        {filterOptions.map(option => (
          <MenuItem key={option.label} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Tooltip>
  </FormControl>
);

export default FilterColumnSelector;
