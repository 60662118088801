export const generateResponse = (userMessage) => {
    const prompts = [
        "That's interesting! Tell me more.",
        "I'm not sure I understand, can you clarify?",
        "Testing a much longer response to see how it will look. The AI has a lot to say. Testing a much longer response to see how it will look. The AI has a lot to say. Testing a much longer response to see how it will look. The AI has a lot to say."
    ];

    // Return a random prompt to simulate a response
    const randomIndex = Math.floor(Math.random() * prompts.length);
    return prompts[randomIndex];
};