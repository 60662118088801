import React from 'react';
import Filter from './FilterComponent.js';
import { useState, useEffect, useRef } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import SummaryStats from './SummaryStats.js';

const AxisOverlay = ({axes, handleAxisChange, onFilterChange, axisOptions, summaryStats}) => {
  
    return (
        <div>
          <div className='absolute z-[50] right-[0.5%] top-[5%] flex items-center justify-end'>
                {/* Filter overlay */}
                <div className='mx-1'>
                    <Filter onFilterChange={onFilterChange} axisOptions={axisOptions}/>
                </div>
            </div>

          <div className='absolute z-[50] left-[2%] top-[40%] items-center justify-left'>
              {/* Y axis */}
              <div>
                <Axis axisLabel={'yAxis'} onAxisChange={handleAxisChange} 
                axisOptions={axisOptions}
                axes={axes}
                defaultAxisVal={axes.yAxis}
                  />
              </div>

          {/* zaxis */}
          <div className={`z-[50] flex mb-2.5 items-center justify-start ${(!axes['zAxis']) ? 'opacity-25' : ''}`}>
              <Axis axisLabel={'zAxis'} onAxisChange={handleAxisChange}
              axisOptions={axisOptions}
              axes={axes}
              defaultAxisVal={axes.zAxis}
              />
          </div>
          </div>

          {/* /* X axis */}
          <div className='absolute z-[50] left-[55%] bottom-0 items-center justify-center transform -translate-x-1/2'>
            <div className={`z-[50] flex mb-2.5 items-center justify-start ${(!axes['xAxis']) ? 'opacity-25' : ''}`}>
              <Axis axisLabel={'xAxis'} onAxisChange={handleAxisChange}
                axisOptions={axisOptions}
                axes={axes}
              />
            </div>
          </div>

          {/* Summary stats in bottom left corner */}
          {/* Summary stats in bottom left corner */}
            <SummaryStats
            summaryStats={summaryStats}
            />
      </div>
    );
};

const Axis = ({ axisLabel, onAxisChange, axisOptions, axes}) => { 
  const [isTextOverflowing, setIsTextOverflowing] = useState(false);
  const selectRef = useRef(null); // Ref for the Select element

  function removeAxis(axis) {
    onAxisChange(axis, '');
  }

  const handleAxisChange = (e) => {
    onAxisChange(axisLabel, e.target.value);
  };

  useEffect(() => {
    const checkOverflow = () => {
      const selectElement = selectRef.current?.querySelector('.MuiSelect-select');
      if (selectElement) {
        setIsTextOverflowing(selectElement.scrollWidth > selectElement.clientWidth);
      }
    };
    // Check overflow initially and whenever axisValue or options change
    checkOverflow();
  }, [axes[axisLabel], axisOptions]);

  // Get label of selected option based on value
  const getSelectedLabel = () => {
    const selectedOption = axisOptions.find(option => option.value === axes[axisLabel]);
    return selectedOption ? selectedOption.label : '';
  };

  return (
    <div className='flex items-center mb-2.5'>
      <FormControl
        className='flex w-fit'
        size='small'
        sx={{
          minWidth: '125px',
          maxWidth: '125px',
        }}
        fullWidth
      >
        <InputLabel
          id="override"
          sx={{ backgroundColor: 'transparent' }}
        >
          {axisLabel === 'xAxis' ? 'X-Axis' : axisLabel === 'yAxis' ? 'Y-Axis' : 'Z-Axis'}
        </InputLabel>

        {/* Conditional tooltip based on text overflow */}
        <Tooltip
          title={isTextOverflowing ? getSelectedLabel() : ''} // Show tooltip only if text overflows
          placement="top"
          PopperProps={{
            style: { zIndex: 25 },
          }}
          arrow
        >
          <Select
            value={axes[axisLabel]}
            label={axisLabel === 'xAxis' ? 'X-Axis' : axisLabel === 'yAxis' ? 'Y-Axis' : 'Z-Axis'}
            onChange={handleAxisChange}
            ref={selectRef} // Ref to measure text overflow
            sx={{
              maxWidth: '125px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {axisOptions.map((axis) => (
              <MenuItem key={axis.value} value={axis.value}>
                {axis.label}
              </MenuItem>
            ))}
          </Select>
        </Tooltip>
      </FormControl>

      {axes[axisLabel] && (
        <IconButton
          onClick={() => removeAxis(axisLabel)}
          sx={{ p: 0.5, borderRadius: '50%', '&:hover': { color: '#F00', backgroundColor: '#F0F0F0' } }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </div>
  );
};

export default AxisOverlay;