import { useState } from 'react';
import Button from '@mui/material/Button';

// Utility function to format date as MM/DD/YYYY
const formatDate = (date) => {
  if (typeof date === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(date)) {
    const [year, month, day] = date.split('-');
    return `${month}/${day}/${year}`;
  }
  return date; // Return as-is if it's not a valid yyyy-mm-dd date string
};

// Utility function to format numbers
const formatNumber = (value, type) => {
  if (typeof value !== 'number') return value;
  if (type === 'float') {
    // Format as float with 2 decimal places if less than 100
    return value < 100 ? value.toFixed(2) : Math.round(value).toLocaleString();
  }
  if (type === 'int') {
    // Format as integer with commas
    return value.toLocaleString();
  }
  return value;
};

const statsDictionary = {
  max: 'float',
  min: 'float',
  std: 'float',
  count: 'int',
  mean: 'float',
};

const SummaryStats = ({ summaryStats }) => {
  const [selectedKey, setSelectedKey] = useState(null);

  if (!summaryStats) {
    // Return early if summaryStats is null or undefined
    return null;
  }

  const handleKeyClick = (key) => {
    setSelectedKey(key);
  };

  // Function to determine if a value is a nested object
  const isNestedObject = (value) => {
    return typeof value === 'object' && value !== null && !Array.isArray(value);
  };

  // Separate nested entries
  const nestedKeys = Object.keys(summaryStats).filter((key) => isNestedObject(summaryStats[key]));
  
  // Safely access properties, default to empty strings if properties are missing
  const sales_lb_cutoff = summaryStats['sales_lb_cutoff'] || '';
  const sales_ub_cutoff = summaryStats['sales_ub_cutoff'] || '';
  const eval_dt = summaryStats['eval_dt'] || '';

  return (
    <div className='z-[500]'>
      <div className='flex flex-col items-start'>
        {/* Display the sentence */}
        <div className='absolute z-[5] left-[40%] top-[7.5%] flex-grow text-sm'>
          <div className='flex p-3 rounded-md'>
            <span>
              {'From'} <span className='font-bold'>{formatDate(sales_lb_cutoff)}</span> {'to'}{' '}
              <span className='font-bold'>{formatDate(sales_ub_cutoff)}</span> {'for eval date'}{' '}
              <span className='font-bold'>{formatDate(eval_dt)}</span>
            </span>
          </div>
        </div>

        {/* Summary Stats Table */}
        <div className='absolute mb-4 z-[5] left-[2%] bottom-[0%] text-sm'>
          {selectedKey ? selectedKey : 'Summary Stats:'}
          <div className='mt-2 p-3 border border-gray-400 h-[250px] w-[200px] rounded-md'>
            {selectedKey ? (
              // Display nested keys and values if a key is selected
              Object.keys(summaryStats[selectedKey] || {}).map((nestedKey) => (
                <div key={nestedKey} className='flex justify-between w-full'>
                  <span className='font-medium'>{nestedKey}:</span>
                  <span className='pl-4'>
                    {formatNumber(summaryStats[selectedKey][nestedKey], statsDictionary[nestedKey])}
                  </span>
                </div>
              ))
            ) : (
              // Display nested keys with view button
              <div className='text-center items-center'>
                {nestedKeys.length > 0 ? (
                  nestedKeys.map((key) => (
                    <div
                      key={key}
                      className='flex justify-between w-full text-left cursor-pointer m-auto'
                      onClick={() => handleKeyClick(key)}
                    >
                      <span className='font-medium pt-[6px]'>{key}:</span>
                      <Button>View</Button>
                    </div>
                  ))
                ) : (
                  <div>No stats available</div>
                )}
              </div>
            )}
            {/* Add a button to go back to the top-level keys */}
            {selectedKey && (
              <button
                className='mt-2 p-1 bg-blue-500 text-white rounded'
                onClick={() => setSelectedKey(null)}
              >
                Back
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryStats;
