import { useMemo } from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Popover from '@mui/material/Popover';
import CompAdditionSearch from './CompAdditionSearch';
import LoopIcon from '@mui/icons-material/Loop';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from 'react';
import { DataGridPro, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport, GridToolbarQuickFilter,
  GridToolbarExportContainer, GridCsvExportMenuItem, gridFilteredSortedRowIdsSelector, gridVisibleColumnFieldsSelector, useGridApiContext } from '@mui/x-data-grid-pro';
import SubjectDataGridRow from './SubjectDataGridRow';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import { toast } from 'react-toastify'; // Assuming you're using react-toastify for notifications
import { saveAs } from 'file-saver'; // Assuming you're using file-saver for saving files
import { CompReportGenerator } from './CompReportGenerator';

// Ultimately this Component ended up being redundant but is fine to seperate these things out a bit mroe from the monolithic MUI table component.
function DataGridContainer({
        addCompCallback,
        discardChanges,
        caseNotesChanged,
        setIsSavingCallback,
        getTogglableColumns,
        miscAdjustments,
        comps,
        userUpdate,
        handleProcessRowUpdate,
        mergedProps,
        dataGridStyles,
        pinnedRows,
        apiRef,
        memoizedColumnVisibilityModel,
        handleColumnVisibilityChange,
        getRowClassName,
        getCellClassName,
        handleProcessRowUpdateError,
        headerResult,
        isDifferent,
        saveChanges,
        subject,
        unsavedChangesRef,
        updateRefWithSubj,
        handleSaveComps,
        handleToggleReordering,
        reordering,
        isSaving,
        savedCompPids,
        selectedRows, // this combines savedcomppids and userUpdate (if user picks, uses that, otherwise use saved.)
        }){

          // define values which will be used for the PDF generator function.
          const location = useLocation();
          const queryParams = new URLSearchParams(location.search);
          const comp = useMemo(() => parseInt(queryParams.get('comp')) - 1 || 0, [queryParams]);
          // use your user object here to pull in from user ID
          // load in the value of given_name from the localstorage userInfo object.
          const userInfo = JSON.parse(localStorage.getItem('userInfo')) || null;
          const userName = userInfo?.given_name || null;

    return(
        // this overlay div should be conditionally displayed based on WHERE we are in the fetch response
        // see if you can use a callback function so that when you invoke the inventory update, it sets a local state down in this?
        // Potentially go one level deeper?
        // or higher? basically don't want the full table rerender if we can avoid but if performant than no stress
        <div className="w-full h-full relative">
            {/* DataGridPro with the toolbar */}
            <DataGridPro
                {...mergedProps}
                className="data-grid-transition"
                sx={dataGridStyles}
                pinnedRows={pinnedRows}
                apiRef={apiRef}
                // loading={isSaving}
                // pagination // Enables pagination
                // pageSize={100}
                columnVisibilityModel={memoizedColumnVisibilityModel}
                onColumnVisibilityModelChange={handleColumnVisibilityChange}
                getRowClassName={getRowClassName}
                getCellClassName={getCellClassName}
                processRowUpdate={(updatedRow, originalRow) => handleProcessRowUpdate(updatedRow, originalRow)}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                slots={{
                    toolbar: () => (
                        <MemoizedToolbar
                            headers={headerResult}
                            caseNotesChanged={caseNotesChanged}
                            comps={comps}
                            userName={userName}
                            isDifferent={isDifferent}
                            saveChanges={saveChanges}
                            memoizedColumnVisibilityModel={memoizedColumnVisibilityModel}
                            subject={subject}
                            selectedRowIds={pinnedRows}
                            userUpdate={userUpdate}
                            selectedRows={selectedRows}
                            unsavedChangesRef={unsavedChangesRef}
                            discardChanges={discardChanges}
                            updateRefWithSubj={updateRefWithSubj}
                            handleSaveComps={handleSaveComps}
                            handleToggleReordering={handleToggleReordering}
                            reordering={reordering}
                            addCompCallback={addCompCallback}
                            setIsSavingCallback={setIsSavingCallback}
                            savedCompPids={savedCompPids}
                            compNumber={comp}
                        />
                    ),
                }}
            />

            {/* Conditionally show the overlay and loading spinner over the grid content */}
            {isSaving && (
                <>
                    {/* Overlay only on the grid content */}
                    {/* Top-[70px] removed */}
                    <div className="absolute inset-0 bg-gray-400 opacity-60 pointer-events-none grid-overlay" />

                    {/* CircularProgress centered in the grid content */}
                    <div className="absolute mt-[70px] inset-0 flex items-center justify-center">
                        <CircularProgress className="opacity-100" 
                        sx={{ color: '#374151' }} 
                        />
                    </div>
                </>
            )}
        </div>
    )
}
export default React.memo(DataGridContainer);

const PDFReportGenerator = ({ subject, compNumber, name, selectedRows }) => {
  // CONVERTED THE subject, compNumbnner, and selected rows into ARRAYS

  if (selectedRows.length === 0) {
    toast.error("No comps selected to generate report.");
    return null; // Return null if no comps are selected
  }

  // signle comp report function, you can take 0th index of the arrays to make this work.
  const handlePrintCompReport = async () => {
    console.log(subject);
    try {
      // Return the PDF from generator function, and save as.
      const reportPDF = await CompReportGenerator({ compNumber, name, selectedRows });
      saveAs(reportPDF, `${subject.PropertyInfo.Address} Valuation.pdf`);
    } catch (error) {
      console.error("Error generating PDF report:", error);
      // Show a notification or alert here to inform the user about the error.
      toast.error("Error generating PDF report.");
    }
  };

  const handleClick = () => {
    handlePrintCompReport();
  };

  return (
    <MenuItem onClick={handleClick}>
      Save Comp Report
    </MenuItem>
  );
};

export function CustomExportButton({subject, compNumber, selectedRows, name}) {
  // pass down the subject field
  // convert subject and compNumber into arrays
  return (
    <GridToolbarExportContainer>
      <GridCsvExportMenuItem options={{fileName: `${subject.PropertyInfo.Address}_comps`, separator: ','}} />
      <PDFReportGenerator subject={subject} compNumber={compNumber} selectedRows={selectedRows}/>
    </GridToolbarExportContainer>
  );
}
    
    // your toolbar component
const MemoizedToolbar = React.memo(({
    headers,
    caseNotesChanged,
    selectedRows,
    userName,
    compNumber,
    isDifferent,
    setIsSavingCallback,
    handleSaveComps,
    selectedRowIds,
    savedCompPids,
    saveChanges,
    userUpdate,
    updateRefWithSubj,
    memoizedColumnVisibilityModel,
    subject,
    discardChanges,
    unsavedChangesRef,
    handleToggleReordering,
    reordering,
    addCompCallback,
  }) => {
    // this will either be the userUpdate or the savedCompPids
    // console.log('selected rows!')
    // console.log(selectedRows)
  
    const hiddenFields = ['actions']; // Specify any fields you want to hide from the toggle
  // Get togglable columns function
  const getTogglableColumns = (columns) => {
    return columns
      .filter((column) => !hiddenFields.includes(column.field))
      .map((column) => column.field);
  };
  
  const [anchorEl, setAnchorEl] = useState(null); // Control popover
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget); // Set the button as the anchor element
  };
  
  const handleClose = () => {
    setAnchorEl(null); // Close the popover
  };
  
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  
  
    return (
      <div>
        <div className='flex items-center justify-between'>
                    <div className='flex items-center'>
                    <div className='toolbar-buttons'>
                      <GridToolbarColumnsButton
                        getTogglableColumns={getTogglableColumns(headers)}
                      />
                      <GridToolbarFilterButton />
                      <CustomExportButton compNumber={compNumber} selectedRows={selectedRows} name={userName} subject={subject}/>
                      <GridToolbarQuickFilter
                      />
                    </div>
                  </div>
  
          {/* Right section - */}
          <div className='flex-shrink-0 flex items-center justify-end' >
        <Button
          aria-describedby={id}
          className='py-0.5 mr-2 inline-flex items-center justify-center whitespace-nowrap'
          variant='outlined'
          onClick={handleClick} // Show the popover on click
          endIcon={<AddIcon className=''/>}
        >
          Add Comp
        </Button>
        
        {/* MUI Popover for searching comps */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose} // Close on clicking outside or selecting something
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {/* comp addition popover */}
          <div className='p-4 relative overflow-visible'>
            <CompAdditionSearch handleClose={handleClose} addCompCallback={addCompCallback}/>
          </div>
        </Popover>
          
          {/* inventory update buttons (discard + update) */}
            {Object.keys(unsavedChangesRef.current.unsavedRows).length || Object.keys(unsavedChangesRef.current.unsavedSubject).length ? (
              <div className='p-0.5'>
                <Button
                  variant='outlined'
                  className='mr-2 hover:border-red-500 hover:text-red-500 py-0.5'
                  // add sx style for onhover border-red-500
                  onClick={() => discardChanges()}
                >
                  Discard
                </Button>
                <Button
                  variant='outlined'
                  className='hover:border-green-600 hover:text-green-600 py-0.5'
                  onClick={saveChanges}
                >
                  Update
                </Button>
              </div>
            ) 
            : 
  
            // If you don't have any selected neither of these display? I guess thats correct.
            // Reordering, or saving comps
              <div className='flex whitespace-nowrap'>
                {reordering ? (
                  // this is a little gross, but gets the point across.
                  <Button
                  variant='contained'
                  onClick={handleToggleReordering}
                  className='w-[120px] h-[30.5px] text-center mr-2 group hover:bg-red-700 items-center transition-all duration-300'
                  endIcon={
                    <span>
                      <LoopIcon className='group-hover:hidden ml-[-10px] h-[20px] items-center' />
                      <CloseIcon className='hidden group-hover:flex ml-[-20px]' />
                    </span>
                  }
                >
                  <span className='group-hover:hidden w-full'>Reordering</span>
                  <span className='hidden group-hover:flex w-full pl-2'>Cancel</span>
                </Button>
                ) : (
                  <Button
                    className='py-0.5 mx-2'
                    variant='outlined'
                    onClick={handleToggleReordering}
                    // Temporarily disabled because not working how you want.
                    disabled={selectedRows.length === 0}
                    // disabled={true}
                  >
                    Reorder
                  </Button>
                )}
              {/* // Save comps */}
              <Button className='py-0.5'
              disabled={!isDifferent && !caseNotesChanged}
              variant="outlined"
              onClick={() => {
                setIsSavingCallback();
                const idsToSave = userUpdate.length > 0 ? userUpdate : selectedRowIds.top;
                handleSaveComps(idsToSave);
              }}
              >
                Save Comps
              </Button>
              </div>
            }
              </div>
        </div>
        <SubjectDataGridRow subject={subject} updateRefWithSubj={updateRefWithSubj} unsavedChangesRef={unsavedChangesRef} headers={headers} columnVisibilityModel={memoizedColumnVisibilityModel} />
      </div>
    );
});