import React, { useState, useEffect, Suspense, useCallback, useMemo } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';
import FAQSection from './FAQSection.js';
import ResultsDisplay from './ResultsDisplay';
import { useLoadScript } from '@react-google-maps/api';
import CaptureForm from './CaptureForm';
import LinkIcon from '@mui/icons-material/Link';
import MailIcon from '@mui/icons-material/Mail';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IosShareIcon from '@mui/icons-material/IosShare';
    
const AddressInput = React.lazy(() => import('./AddressInput'));

function LoadingSpinner() {
  return (
    <div className="flex justify-center mt-4">
      <CircularProgress />
    </div>
  );
}

function calculateFutureValue(annualOverpayment, years, annualReturnRate) {
  const rate = annualReturnRate / 100; // Convert to a decimal
  let futureValue = 0;

  for (let i = 0; i < years; i++) {
      // Each year, add the contribution and apply compound interest
      futureValue = (futureValue + annualOverpayment) * (1 + rate);
  }

  return futureValue.toFixed(0); // Format the result to 2 decimal places
}
const libraries = ['places'];

function HomeownerTaxReduction() {
  const [county, setCounty] = useState('');
  // It sets the fee dynamically here based on the county you select.
  const feeParam = useMemo(() => { if(county==='Nassau'){return 40;}else{return 50}}, [county]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const addressParam = useMemo(() => {
    const addressParam = new URLSearchParams(window.location.search).get('address');
    return addressParam ? decodeURIComponent(addressParam) : '';
  }, [window.location.search]);
  const [propertyAddress, setPropertyAddress] = useState(addressParam ? decodeURIComponent(addressParam) : '');

  // attempt to properly set the default data values
  const [data, setData] = useState(() => {
    // Check if the address exists in localStorage
    if (localStorage.getItem('PropertyOAData')) {
      const localData = JSON.parse(localStorage.getItem('PropertyOAData'));
      const localAddressPart = addressParam.split(',')[0].trim();  // Get the address part from the URL

      // Check if the address matches the one in localStorage
      if (localData.address === localAddressPart) {
        console.log('The address matches the localStorage');
        return localData;  // Return the local data as the default state
      }
    }
    return null;  // If no match, return null as the default state
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [pendingAddress, setPendingAddress] = useState(null);
  const [showCaptureForm, setShowCaptureForm] = useState(false);
  const [linkText, setLinkText] = useState('Link'); // state for the Link menu item text

  const shareMessage = useMemo(() => {
    if (data && data.annualOverpayment) {
      return `I just learned I overpay my property taxes by $${data.annualOverpayment}. This company shows you what you can save for free, might be worth checking.`;
    } else {
      return `I just found this company that can show you property tax savings for free. It might be worth checking.`;
    }
  }, [data]);
  
  const [anchorEl, setAnchorEl] = useState(null);
  const [buttonText, setButtonText] = useState('Share');

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyBN9R9juT2OIjJkc3SHTTMC_ORmXS-fOxU",
    libraries: libraries,
  });


  // FOR THE SHARE BUTTON:
  const handleClick = (event) => {
    const isMobile = /Mobi|Android|iPhone|iPad|iPod/i.test(window.navigator.userAgent) || window.navigator.maxTouchPoints > 1;
  
    if (isMobile && navigator.share) {
      // If the user is on a mobile device and Web Share API is available, show the native share dialog
      navigator.share({
        title: 'Property Tax Overpayment',
        text: shareMessage,
        url: 'https://proprietyrealty.com/save-taxes', // Replace with your actual URL
      }).then(() => {
        console.log('Share successful');
      }).catch((error) => {
        console.error('Error sharing:', error);
      });
    } else {
      // Fallback to opening the menu if the Web Share API is not available or the user is not on a mobile device
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null); // Close the menu
  };

  // Link sharing buttons.
  const handleLinkCopy = () => {
    setLinkText('Copied!'); // change the text for 2 seconds
    setTimeout(() => {
      setLinkText('Link'); // reset the text after 2 seconds
    }, 2000);
  };

  // This doesn't seem to be working
  const handleEmailShare = () => {
    // Optional: Open default email client with pre-filled subject and body
    window.location.href = `mailto:?subject=Property Tax Overpayment&body=${encodeURIComponent(shareMessage)}`;
    handleClose();
  };

  const geocodeValidation = (address, callback) => {
    if (!window.google || !window.google.maps) {
      console.error("Google Maps JavaScript API is not loaded.");
      return;
    }

    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ address }, (results, status) => {
      if (status === 'OK' && results.length > 0) {
        let countyName = "", stateName = "", muniName = "";
        for (const component of results[0].address_components) {
          const componentType = component.types[0];
          switch (componentType) {
            case "administrative_area_level_3":
              muniName = component.long_name;
              break;
            case "administrative_area_level_2":
              countyName = component.long_name.replace(/ County$/, '').trim();
              break;
            case "administrative_area_level_1":
              stateName = component.long_name;
              break;
            default:
              break;
          }
        }
        const propertyInfo = {
          county: countyName,
          state: stateName,
          municipality: muniName,
        };

        if (stateName === "New York" && ["Nassau", "Suffolk", "Westchester", "Delaware", "Dutchess", "Columbia", "Greene", "Orange", "Putnam", "Rockland", "Sullivan", "Ulster"].includes(countyName)) {
          setCounty(countyName);
          callback(true, propertyInfo);
        } else {
          callback(false, propertyInfo);
        }
      } else {
        console.error("Geocode lookup failed:", status);
        callback(false);
      }
    });
  };

  // when user selects an actual address
  const handleSelect = useCallback((address) => {
    // Check if the address matches the one in local storage. If it does, do the below, otherwise clear localstorage.
    if (localStorage.getItem('PropertyOAData')) {
      const localData = JSON.parse(localStorage.getItem('PropertyOAData'));
      const localAddressPart = address.split(',')[0].trim();
      if (localData.address === localAddressPart) {
        console.log('the address matches the localstorage');
        setPropertyAddress(address);
        setData(localData);
        if (loading) setLoading(false);
        return;
      } else {
        console.log('the address does not match the localstorage');
        localStorage.removeItem('PropertyOAData');
      }
    }

    // Initialize queryParams from the current URL.
    const queryParams = new URLSearchParams(window.location.search);

    // Reset formStep if it's present in the query
    if (queryParams.has('formStep')) {
      console.log('resetting the form step');
      queryParams.set('formStep', '1'); // Set formStep to 1 if not already present
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState(null, '', newUrl); // Update the URL with the new formStep
    }

    // If the address hasn't been searched yet, set pending address for geocode validation.
    if (!isLoaded) {
      console.log('it snot loaded');
      setPendingAddress(address);
      return;
    }

    // Geocode validation happens if the script is loaded
    geocodeValidation(address, (isValid, propertyInfo) => {
      console.log('running geocode validation because the script is loaded.');

      if (!isValid) {
        setErrorMessage(`We do not currently operate in ${propertyInfo?.county}, sign up to be notified when we do`);
        setPropertyAddress(address);
        return;
      }

      setErrorMessage('');
      setPropertyAddress(address);

      // Initialize queryParams again (to ensure it's fresh)
      const updatedQueryParams = new URLSearchParams(window.location.search);

      // Set or update the 'address' parameter
      updatedQueryParams.set('address', address);

      // Update the URL without eliminating other existing parameters
      const newUrl = `${window.location.pathname}?${updatedQueryParams.toString()}`;
      window.history.pushState(null, '', newUrl);

      // Fetch data with the address and property information
      fetchData({ address: address, propertyInfo });
    });
  }, [isLoaded]);


  // Run geocodeValidation when isLoaded becomes true and there's a pending address. this only should run if address exists before the goog api script loads.
  // (this happens when it extracts address from url param)
  useEffect(() => {
    if (isLoaded && pendingAddress) {
      console.log('the useeffect validation is happening')
      geocodeValidation(pendingAddress, (isValid, propertyInfo) => {
        if (!isValid) {
          setErrorMessage(`We do not currently operate in ${propertyInfo.county}, sign up to be notified when we do`);
          setPropertyAddress(pendingAddress);
          return;
        }
        setErrorMessage('');
        setPropertyAddress(pendingAddress);
        const currentParams = new URLSearchParams(window.location.search);
        // Set or update the 'address' parameter
        currentParams.set('address', pendingAddress);
  
        // Update the URL without eliminating other existing parameters
        const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
        window.history.pushState(null, '', newUrl);
  
        fetchData({ address: pendingAddress, propertyInfo });
      });
      setPendingAddress(null); // Clear pending address after processing
    }
  }, [isLoaded, pendingAddress]);

  // Handle input change for the address
  const handleChange = useCallback((address) => {
    setPropertyAddress(address);
  }, []);

  // Handle the back button
  const handleBack = useCallback(() => {
    // Clear the address and form information
    setPropertyAddress('');
    setData(null);
  
    // Clear out the localStorage for PropertyOAData
    localStorage.removeItem('PropertyOAData');
    
    // Update the URL to remove any query parameters
    window.history.pushState(null, '', window.location.pathname);
  }, []);

  // Fetch data based on the address -- have this return ALSO INCLUDE THE PID (so you can use that as referrer info?)
  const fetchData = ({address, propertyInfo}) => {
    // Truncate this address after the first comma:
    const commaIndex = address.indexOf(',');
    if (commaIndex !== -1) {
      address = address.substring(0, commaIndex);
    }
    console.log(propertyInfo);
    const annualOverpayment = 1500;
    const years = 30; // Number of years
    const annualReturnRate = 7; // Average annual return rate (S&P 500)
    const result = calculateFutureValue(annualOverpayment, years, annualReturnRate);

    // Update the analysis to be for the address, and subheader for the muni in the card.
    setLoading(true);
    const dataResponse = {
        address: address,
        municipality: propertyInfo?.municipality || propertyInfo?.county,
        overpaymentPercent: 47,
        annualOverpayment: parseInt(annualOverpayment).toLocaleString(),
        cumulativeOverpayment: parseInt(result).toLocaleString(),
      }
    setTimeout(() => {
      setData(dataResponse);
      setLoading(false);
      // here, set the localstorage with this data object:
      console.log('first response received, setting the localstorage')
      localStorage.setItem('PropertyOAData', JSON.stringify(dataResponse));
    }, 2000);
  };

  const handleSignUpClick = () => {
    if (errorMessage) {
      setShowCaptureForm(true);
      setErrorMessage(''); // Clear error state when the form is rendered
    }
  };

  // Check for the address parameter in the URL and validate it
  useEffect(() => {
    if(data){
      console.log('data exists')
      return;
    }
    if (propertyAddress) {
      console.log('useeffect running') // this is a re-render, that is proper.
      handleSelect(propertyAddress);  // Assuming the address from the URL is valid for now
    }
  }, []);

  return (
    <div className="w-full h-full p-2">
      {data ? (
        // Can move this button into the results container instead
        null
      ) : (
        <Paper elevation={3} className="p-4 text-center">
          <Typography variant="h4" className="mb-4 font-semibold">
            See if you overpay property taxes
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={8} md={6}>
              <Suspense fallback={<CircularProgress />}>
                <AddressInput
                  value={propertyAddress}
                  libraries={libraries}
                  isLoaded={isLoaded}
                  loading={loading}
                  loadError={loadError}
                  onChange={handleChange}
                  onSelect={handleSelect} 
                />
              </Suspense>
            </Grid>
          </Grid>
        </Paper>
      )}

        {errorMessage && (
          <div className='text-center'>
            <Typography variant="body1" color="error">
              {errorMessage}
            </Typography>
            <Button variant="contained" color="primary" onClick={handleSignUpClick}>
              Sign Up
            </Button>
          </div>
        )}

        {/* This form is the non-active form */}
      {showCaptureForm && (
      <Paper elevation={3} className="p-4 relative">
        <CaptureForm feePercent={feeParam} service={false}/>
      </Paper>
      )}

      {data && <ResultsDisplay data={data} handleBack={handleBack} feePercent={feeParam}/>}

      {/* This div only displays to space out the refer block.  */}
      {/* {data? null : <div className="h-[161px] flex items-center justify-center">

        <Typography variant="h6" className="text-center">
        Over 33% of homeowners overpay their property taxes.
        </Typography>
        </div>} */}

      <div elevation={3} className={`${data || errorMessage?'mt-0': 'mt-0'} p-4 items-center justify-center content-center text-center`}>
          <Typography variant="body2" className="">
            Friends don't let friends overpay their taxes:
          </Typography>
          
          <Button 
            onClick={handleClick} 
            color="primary"
            endIcon={<IosShareIcon sx={{ fontSize: '1rem' }} className='mb-1 ml-[-5px]'/>}
            className='mt-2'
            variant="outlined"
          >
            {buttonText}
          </Button>
          {/* Share Menu */}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {/* Link Option */}
            <CopyToClipboard text={shareMessage} onCopy={handleLinkCopy}>
              <MenuItem sx={{ color: linkText === 'Copied!' ? 'primary.main' : 'inherit' }}>
                <LinkIcon sx={{ mr: 1 }} /> {linkText}
              </MenuItem>
            </CopyToClipboard>
    
            {/* Email Option */}
            <MenuItem onClick={handleEmailShare}>
              <MailIcon sx={{ mr: 1 }} /> Email
            </MenuItem>
          </Menu>
      </div>

      {/* <div className="fixed bottom-0 left-0 right-0 z-10 overflow-y-auto"> */}
      <div className='mt-12'>
      <FAQSection feePercent={feeParam}/>
      </div>
      {/* </div> */}
    </div>
  );
}

export default HomeownerTaxReduction;
