import React from 'react';
import ReactLoading from 'react-loading';
import { usePersistedDataStore } from '../components/store';

const LoadingOverlay = ({ progress }) => {
  const getCompSheet = usePersistedDataStore((state) => state.compSheet);
    return (
        <div style={{
            position: 'fixed', // Use 'fixed' to cover the whole viewport
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(107, 114, 128, 0.4)', // gray-400 with 40% opacity
            zIndex: 100,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column', // Center the loading spinner and text vertically
          }}>
            {/* //  <div className="fixed inset-0 bg-gray-500 bg-opacity-40 z-100 flex items-center justify-center flex-col"> */}
            <ReactLoading type="spin" color="#000" />
            <p>Generating reports... {progress}/{getCompSheet.length}</p>
          </div>
  
    );
};

export default LoadingOverlay;