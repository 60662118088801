import React, { useState, useEffect } from 'react';
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation } from 'react-router-dom';
import { usePersistedDataStore } from "../store";
import axiosInstance from "../../axiosConfig";
import AddIcon from '@mui/icons-material/Add';

function CompAdditionSearch({ handleClose, addCompCallback }) {
    const [searchTerm, setSearchTerm] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);
    const [isPidSearch, setIsPidSearch] = useState(false);
    const getCompSheet = usePersistedDataStore((state) => state.compSheet);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const comp = parseInt(params.get('comp')) - 1;
    const handleInputChange = (event) => {
      setLoading(true);
      setSearchTerm(event.target.value);
  };

    // Debounce effect: update debouncedTerm only after user stops typing for 500ms
    useEffect(() => {
        const handler = setTimeout(() => {
            if (searchTerm.length > 0) {
                setDebouncedTerm(searchTerm);
            } else if (loading === true || suggestions.length > 0 || debouncedTerm !== '') {
                // Reset states to default if there is no search term
                setLoading(false);
                setSuggestions([]);
                setDebouncedTerm('');
            }
        }, 500); // 500ms debounce time

        // Cleanup the timeout if searchTerm changes before 500ms
        return () => clearTimeout(handler);
    }, [searchTerm]);

    // Perform the search when debouncedTerm changes
    useEffect(() => {
        const fetchData = async () => {
            if (debouncedTerm) {
                console.log(debouncedTerm);

                // Check if it's a PID search and update the state
                const isPidSearchPattern = /^[A-Za-z]\d{4}/.test(debouncedTerm) && /-/.test(debouncedTerm);
                setIsPidSearch(isPidSearchPattern);

                // Set search locale based on isPidSearch
                let searchLocale = isPidSearchPattern ? getCompSheet[comp]?.parcel_id : getCompSheet[comp]?.Address;

                // Search through the search locale for a partial match of the term
                let found = searchLocale?.filter((value) => value.toLowerCase().includes(debouncedTerm.toLowerCase()));

                console.log(found);

                // Set the suggestions if matches are found
                if (found) {
                    setSuggestions(found);
                }
                setLoading(false);
            }
        };

        fetchData();
    }, [debouncedTerm, getCompSheet, comp]);

    return (
        <div>
            <TextField
      id="standard-basic"
      placeholder="Search by PID or Address"
      variant="standard"
      fullWidth
      value={searchTerm}
      onChange={handleInputChange}
      autoComplete='off'
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: loading && <CircularProgress size={20} />,
      }}
    />
            {/* Render suggestions */}
            {suggestions.length > 0 && (
                <ul>
                    {suggestions.map((suggestion, index) => (
                        <li key={index}>{suggestion}</li>
                    ))}
                </ul>
            )}

{debouncedTerm && suggestions.length === 0 && (
  <ul className="fixed mt-1 bg-white border shadow-md w-fit p-1 rounded-md z-10">
    {isPidSearch ? (
      <li 
        className="px-4 py-2 text-center cursor-pointer hover:bg-gray-200"
        onClick={() => addCompCallback(debouncedTerm)}
      >
        <AddIcon/>
        Add PID comp
      </li>
    ) : (
      <li 
        className="px-4 py-2 text-center cursor-pointer"
        onClick={addCompCallback}
      >
        No matching addresses
      </li>
    )}
  </ul>
)}
        </div>
    );
}
export default CompAdditionSearch;
